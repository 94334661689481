import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { of, catchError, finalize, map, mergeMap, tap } from 'rxjs';
import { userActions } from '../actions/user.actions';
import { HttpService } from '../services/http.service';
import { LoadersFacade } from '@app/store/loader';
import { ChangePasswordError } from '../models';
import { MessageService } from '@corelabs/angular-messages';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageService } from '@app/i18n';

@Injectable()
export class UserEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly messageService = inject(MessageService);
    private readonly languageService = inject(LanguageService);
    private readonly translateService = inject(TranslateService);

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.getUser),
            mergeMap(() => {
                this.loadersFacade.add('get-users');
                return this.httpService.getUser().pipe(
                    map((user) => userActions.getUserSuccess({ user })),
                    catchError(() => of(userActions.getUserError())),
                    finalize(() => this.loadersFacade.delete('get-users')),
                );
            }),
        );
    });

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.changePassword),
            concatLatestFrom(() => this.languageService.language$),
            mergeMap(([{ value }, lang]) => {
                this.loadersFacade.add('change-password');

                return this.httpService.changePassword(value, lang).pipe(
                    map(() => userActions.changePasswordSuccess()),
                    catchError((error: HttpErrorResponse) => of(userActions.changePasswordError({ error }))),
                    finalize(() => this.loadersFacade.delete('change-password')),
                );
            }),
        );
    });

    changePasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userActions.changePasswordSuccess),
                tap(() => {
                    this.messageService.add(this.translateService.instant('change-password-success'), 'success');
                }),
            );
        },
        { dispatch: false },
    );

    changePasswordError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(userActions.changePasswordError),
                tap(({ error }) => {
                    const children = (error.error as ChangePasswordError | undefined)?.errors?.children;
                    if (!children) {
                        return;
                    }

                    Object.values(children).forEach(({ errors }) => {
                        errors?.forEach((error) => {
                            this.messageService.add(error, 'danger');
                        });
                    });
                }),
            );
        },
        { dispatch: false },
    );
}
