import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';
import { USER_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state: UserState) => state.loaded);
const selectUser = createSelector(selectState, (state: UserState) => state.user);
const selectFeatures = createSelector(selectState, (state: UserState) => state.user?.features ?? []);
const selectBlocked = createSelector(selectState, (state: UserState) =>
    state.user?.salespersonInfo?.blocked !== undefined ? state.user.salespersonInfo.blocked : null,
);

const selectSkipCreditLimitVerification = createSelector(selectState, (state: UserState) =>
    state.user?.salespersonInfo?.skipCreditLimitVerification !== undefined ? state.user.salespersonInfo.skipCreditLimitVerification : null,
);

export { selectLoaded, selectUser, selectBlocked, selectSkipCreditLimitVerification, selectFeatures };
