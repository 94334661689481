import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '@env';
import { ApiResponse } from '@app/shared/models';
import { Lang } from '@app/i18n/lang.type';
import { ChangePasswordValue, ChangePasswordValueDto, User, UserDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getUser(): Observable<User> {
        return this.httpClient
            .addUserSwitch()
            .get<ApiResponse<UserDto>>(`api/v1/users/me`)
            .pipe(map((response) => new User(response.data)));
    }

    changePassword(value: ChangePasswordValue, lang: Lang) {
        const headers = environment.supportedLanguages.includes(lang) ? { 'Accept-Language': lang } : undefined;
        const params = new ChangePasswordValueDto(value);

        return this.httpClient.put('api/v1/users/password', params, { headers });
    }
}
