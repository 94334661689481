import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';
import { IconsModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, IconsModule, TranslateModule],
    declarations: [LanguageSwitchComponent],
    exports: [LanguageSwitchComponent],
})
export class I18nModule {}
