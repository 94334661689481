{
  "change-password-success": "Password has been changed",
  "select": "Select",
  "preview": "Preview",
  "edit": "Edit",
  "payment-failed": "Payment failed",
  "pending-payment-error": "The payment is in progress by another user. Please try again in a few minutes.",
  "pending-payment-alert": "Please be advised that the {{documents}} document is currently being processed by another user. Please try again in a few minutes",
  "copyright": "Copyright © 2024. All Rights Reserved.",
  "select-address-info": "Before placing your order, please select the correct delivery address",
  "user-blocked": "User is blocked",
  "back": "Back",
  "more": "More",
  "details": "Details",
  "gross": "Gross",
  "search": "Search",
  "filters": "Filters",
  "title": "Mille Sapori",
  "active": "Active",
  "unactive": "Unactive",
  "loading": "Loading...",
  "cart-name": "Cart",
  "quantity": "Quantity",
  "table.no-content": "No content",
  "table.loading": "Loading...",
  "table.sort": "Sorting",
  "table.asc": "ascending",
  "table.desc": "descending",
  "header.login": "Sign in",
  "header.account-menu.change-password": "Change password",
  "header.account-menu.logout": "Log out",
  "header.nav.dashboard": "Dashboard",
  "header.nav.products": "Products",
  "header.nav.documents": "Documents",
  "header.nav.categories": "Categories",
  "login.welcome": "Welcome to our website!",
  "login.provide-credentials": "Enter your credentials below:",
  "login.title": "Login",
  "login.forgot-password": "Forgot password?",
  "login.forgot-password-click-here": "Forgot password? Click here:",
  "login.recover-password": "Recover password",
  "login.input.email": "E-mail address*",
  "login.input.password": "Password*",
  "login.cta.sign-in": "Sign in",
  "login.cta.remind-now": "Remind now",
  "login.cta.remember-login-data": "Remember login data",
  "reset-password.title": "Reset password",
  "reset-password.remember-password": "Remember password?",
  "reset-password.input.email": "E-mail address*",
  "reset-password.cta.reset": "Reset password",
  "reset-password.cta.sign-in": "Sign in",
  "products.filters": "Special Offers",
  "products.title": "Products list",
  "products.open-card.error": "Unable to download product card",
  "products.table.filters.price": "Product price",
  "products.table.filters.discounts.any": "Select discount",
  "products.table.filters.discounts.promotions": "PROMOTIONS",
  "products.table.filters.discounts.novelties": "NOVELTIES",
  "products.table.filters.checkbox-category.recently": "recently ordered",
  "products.table.filters.checkbox-category.less-frequently": "less frequently ordered",
  "products.table.header.product-name": "Product name",
  "products.table.header.product-number": "Product number",
  "products.table.header.list-price": "List price",
  "products.table.header.discount": "Discount",
  "products.table.header.discounted-price": "Price after discount",
  "products.table.header.vat": "VAT",
  "products.table.header.unit": "Measure",
  "products.table.header.quantity": "Quantity",
  "products.table.header.stock-availability": "Stock availability",
  "products.table.header.availability": "Availability",
  "products.table.pcs": "pcs.",
  "products.table.large-amount": "Large amount",
  "products.table.expected-delivery-date": "Expected delivery date:",
  "products.table.confirmed-delivery-date": "Confirmed delivery date:",
  "products.table.expected-quantity": "Expected quantity:",
  "products.details.back": "Go back",
  "products.details.add-to-cart": "Add to cart",
  "products.details.added-to-cart": "Added to cart",
  "products.details.stock-availability": "Stock availability",
  "products.details.availability": "Availability",
  "products.details.manufacturer-code": "Manufacturer code",
  "products.details.description": "Product description",
  "products.details.open-card": "Produkt card",
  "products.favourites.button": "Favourites",
  "products.discounts-popup.title": "Discount!",
  "products.discounts-popup.description": "When you buy more pieces of this product, you get better unit prices (net)!",
  "products.discounts-popup.old-price": "Last regular price",
  "products.discounts-popup.regular-price": "Current promotional price",
  "products.discounts-popup.promo-price": "Price for",
  "products.discounts-popup.min-pcs": "minimum purchase of {{pcs}} pieces",
  "products.discounts-popup.no-discount": "Discounts are not available for selected unit of measure",
  "favourites.added-to-favourites": "Added to favourites",
  "favourites.removed-from-favourites": "Removed from favourites",
  "favourites.title": "Favourites",
  "favourites.products.button": "All products",
  "set-password.title": "Change password",
  "set-password.input.password": "Password",
  "set-password.input.repeat-password": "Repeat new password",
  "set-password.cta.reset": "Reset password",
  "customers.title": "Purchasers list",
  "customers.table.header.customer-id": "Customer ID",
  "customers.table.header.company-name": "Company name",
  "customers.table.header.second-name": "Name 2",
  "customers.table.header.restaurant-name": "Restaurant name",
  "customers.table.header.blocked": "Blocked",
  "customers.table.header.city": "City",
  "customers.table.header.display-as": "Display as",
  "customers.table.header.vat-registration-no": "VATIN",
  "customers.blocked.All": "All",
  "customers.blocked.Invoice": "Invoice",
  "customers.blocked.Ship": "Ship",
  "customers.blocked.Vindication": "Vindication",
  "forms.error.message.date-range": "Invalid date range",
  "forms.error.message.required": "This field is required",
  "forms.error.message.postal-code": "Invalid postal code",
  "forms.error.message.invalid_address": "Enter the name of the city, street and house number",
  "forms.error.message.invalid_street": "Enter the street name and house number",
  "forms.error.message.invalid_street_number": "Enter the house number",
  "forms.error.message.new_password": "The new password must be the same in both fields",
  "forms.error.message.max_length": "Maximum number of characters exceeded",
  "forms.error.message.min_length": "Minimum number of characters: {{min_length}}",
  "forms.error.message.max": "Maximum value: {{max}}",
  "forms.error.message.invalid_url": "Invalid url",
  "forms.error.message.general": "Invalid field value",
  "forms.error.message.at_least_one_capital": "The field should contain a capital letter",
  "forms.error.message.at_least_one_lower": "The field should contain a lowercase letter",
  "forms.error.message.at_least_one_number": "The field should contain a number",
  "forms.error.message.at_least_one_symbol": "The field should contain a symbol - !@#$%^&*()",
  "forms.price-range.from": "From",
  "forms.price-range.to": "To",
  "forms.dropdown.placeholder": "Select Value",
  "forms.dropdown.placeholder-all": "All",
  "dashboard.welcome.welcome": "Welcome",
  "dashboard.welcome.message-1": "  Welcome to the new portal, thanks to which you can place an order much faster!",
  "dashboard.welcome.message-2": "Here you have access to all MILLE SAPORI IF&B products!",
  "dashboard.welcome.message-3": "The highest quality certified Italian products at your fingertips!",
  "dashboard.welcome.view-products": "View products",
  "dashboard.welcome.activity": "Activity",
  "dashboard.balance.your-balance": "Your balance is",
  "dashboard.balance.credit-limit": "Your trade limit is",
  "dashboard.balance.liabilities": "Sum of all liabilities",
  "dashboard.balance.full-info": "Full information",
  "dashboard.balance.price-gross": "Gross price",
  "dashboard.balance.sales-info.seller": "Your sales advisor",
  "dashboard.balance.sales-info.write-now": "Write now",
  "dashboard.latest-documents.title": "Latest sales documents",
  "dashboard.latest-documents.all-documents": "All documents",
  "dashboard.buyer-details": "Buyer details",
  "dashboard.payment-method": "Payment method",
  "cl.account.login-success": "Successfully logged in",
  "cl.account.login-invalid-credentials": "Wrong password or email address",
  "cl.account.account_not_active": "Account not activated",
  "cl.account.eip_user_blocked_reason_all": "The account has been blocked. Please contact your administrator",
  "cl.account.login-not-activated": "Account has not been activated",
  "cl.account.logout-success": "You have successfully logged out",
  "cl.account.logout-error": "Error while logging out",
  "cl.account.reset-password-request-success": "Password reset email sent",
  "cl.account.reset-password-request-error": "An error occurred while processing the request",
  "cl.account.reset-password-success": "Password has been updated",
  "cl.account.reset-password-error": "The password reset link is invalid",
  "cl.account.activate-success": "The account has been activated",
  "cl.account.activate-error": "An error occurred while activating your account",
  "cl.account.resend-activation-link-success": "Activation link has been sent",
  "cl.account.resend-activation-link-error": "An error occurred while processing your request",
  "profile.account-settings.title": "Account settings",
  "profile.account-settings.change-password": "Change password",
  "profile.account-settings.current-password": "Current password",
  "profile.account-settings.new-password": "New password",
  "profile.account-settings.repeat-password": "Repeat new password",
  "admin.nav.users-list": "Users List",
  "admin.nav.notifications": "Notifications",
  "admin.nav.banners": "Banners",
  "admin.nav.welcome-messages": "Welcome messages",
  "admin.nav.settings": "Portal settings",
  "admin.table.users.title": "List of users",
  "admin.table.users.add-new-user-button": "Add new user",
  "admin.table.users.salesperson": "Salesperson:",
  "admin.table.users.deputy": "Deputy:",
  "admin.table.users.header.customer-id": "Customer ID",
  "admin.table.users.header.email": "Email address",
  "admin.table.users.header.role": "Role",
  "admin.table.users.header.activity": "Activity",
  "admin.table.users.header.salesperson": "Salesperson",
  "admin.table.users.filters.select-role": "Select roles",
  "admin.table.users.filters.select-activity": "Select activity",
  "admin.table.user-logs.title": "User Activity",
  "admin.table.user-logs.header.log-date": "Login date",
  "admin.table.user-logs.header.status": "Status",
  "admin.table.user-logs.status.user-create": "User created",
  "admin.table.user-logs.status.user-fail-logged": "User login failure",
  "admin.table.user-logs.status.user-locked": "User locked",
  "admin.table.user-logs.status.user-success-logged": "Login completed successfully",
  "admin.table.user-logs.status.user-update": "User update",
  "admin.table.user-logs.status.user-delete": "User deleted",
  "admin.table.user-logs.status.generate-password": "Password generated",
  "admin.table.user-logs.status.user-success-password-change": "User password changed successfully",
  "admin.table.user-logs.status.user-fail-password-change": "Failure to change user password",
  "admin.table.user-logs.status.user-unlocked": "User unlocked",
  "admin.table.user-logs.status.user-notification-create": "Notification created",
  "admin.table.user-logs.status.user-notification-delete": "Notification deleted",
  "admin.table.user-logs.status.user-notification-update": "Notification update",
  "admin.table.user-logs.status.user-welcome-message-create": "Welcome message created",
  "admin.table.user-logs.status.user-welcome-message-delete": "Welcome message deleted",
  "admin.table.user-logs.status.user-welcome-message-update": "Welcome message update",
  "admin.table.user-logs.status.user-banner-create": "Banner created",
  "admin.table.user-logs.status.user-banner-delete": "Banner deleted",
  "admin.table.user-logs.status.user-banner-update": "Banner Update",
  "admin.table.user-logs.status.user-success-order": "Order successful",
  "admin.table.user-logs.status.user-fail-order": "Order failed",
  "admin.table.notifications.title": "Notification List",
  "admin.table.notifications.create-new-notifications-button": "Create new alert",
  "admin.table.notifications.header.title": "Title",
  "admin.table.notifications.header.description": "Text",
  "admin.table.notifications.header.active-date-from": "Active since",
  "admin.table.notifications.header.active-date-to": "Active until",
  "admin.table.notifications.header.edit": "Edit",
  "admin.table.banners.title": "Banner List",
  "admin.table.banners.create-new-banners-button": "Create new banner",
  "admin.table.banners.header.title": "Title",
  "admin.table.banners.header.active-date-from": "Active since",
  "admin.table.banners.header.active-date-to": "Active until",
  "admin.table.banners.header.edit": "Edit",
  "admin.table.welcome-messages.title": "Welcome messages list",
  "admin.table.welcome-messages.create-new-button": "Create new message",
  "admin.table.welcome-messages.header.name": "Name",
  "admin.table.welcome-messages.header.description": "Message",
  "admin.table.welcome-messages.header.date-from": "Active from",
  "admin.table.welcome-messages.header.date-to": "Active to",
  "admin.table.welcome-messages.header.active": "Visibility",
  "admin.table.welcome-messages.header.edit": "Edit",
  "admin.users.title": "Managing {{email}}",
  "admin.users.nav.edit": "Edit",
  "admin.users.nav.activity": "Activity",
  "admin.users.add.title": "Adding user",
  "admin.users.add.set-role": "Grant role",
  "admin.users.add.add-user": "Add user",
  "admin.users.add.back": "Back",
  "admin.users.add.user-has-been-added": "User has been added",
  "admin.users.add.errors.email.exists": "This email already exists.",
  "admin.users.add.errors.eip.customer_user_email_not_found": "Customer user with this email address could not be found in BC.",
  "admin.users.edit.title": "User Edit",
  "admin.users.edit.set-role": "Grant role",
  "admin.users.edit.save": "Save",
  "admin.users.edit.back": "Back",
  "admin.users.edit.user-has-been-added": "The user has been updated",
  "admin.users.edit.update-success": "User updated",
  "admin.users.edit.delete-success": "User deleted",
  "admin.users.edit.delete-title": "Deleting user",
  "admin.users.edit.delete-description": "Are you sure you want to delete the user",
  "admin.users.edit.delete-yes": "Yes",
  "admin.users.edit.delete-no": "No",
  "admin.users.edit.blocked": "Blocked",
  "admin.users.edit.unblocked": "Unblocked",
  "admin.users.edit.delete": "Delete",
  "admin.users.edit.change-password": "Change password",
  "admin.users.edit.change-password-title": "Are you sure you want to reset the password for this user?",
  "admin.users.edit.reset-password": "Reset password",
  "admin.users.edit.change-password-success": "The user's password was successfully generated",
  "admin.users.edit.errors.email.exists": "This email already exists.",
  "admin.notifications.add.title": "Add notification",
  "admin.notifications.add.name": "Name",
  "admin.notifications.add.description": "Description",
  "admin.notifications.add.start-date": "Start date",
  "admin.notifications.add.end-date": "End date",
  "admin.notifications.add.choose-date": "Choose date",
  "admin.notifications.add.submit-button": "Add notification",
  "admin.notifications.add.visible": "Visible",
  "admin.notifications.add.invisible": "Invisible",
  "admin.notifications.add.alert-visibility": "Notification visibility",
  "admin.notifications.add.notification-has-been-added": "Notification has been added",
  "admin.notifications.edit.title": "Edit {{notification}}",
  "admin.notifications.edit.update-success": "Notification updated",
  "admin.notifications.edit.delete-success": "Notification deleted",
  "admin.notifications.edit.save": "Save",
  "admin.notifications.edit.delete-title": "Deleting notification",
  "admin.notifications.edit.delete-description": "Are you sure you want to delete the notification?",
  "admin.settings.title": "Portal settings",
  "admin.settings.select-default-category": "Select default category",
  "admin.settings.save": "Save",
  "admin.settings.success": "Changes were saved",
  "admin.settings.error": "Failed to save changes",
  "admin.banners.add.title": "Add banner",
  "admin.banners.add.name": "Title",
  "admin.banners.add.url": "URL",
  "admin.banners.add.start-date": "Start date",
  "admin.banners.add.end-date": "End date",
  "admin.banners.add.image": "Photo",
  "admin.banners.add.banner-visibility": "Banner visibility",
  "admin.banners.add.visible": "Visible",
  "admin.banners.add.invisible": "Invisible",
  "admin.banners.add.submit-button": "Add banner",
  "admin.banners.add.banner-has-been-added": "Banner has been added",
  "admin.banners.edit.title": "Edit {{banner}}",
  "admin.banners.edit.submit-button": "Save",
  "admin.banners.edit.update-success": "Banner updated",
  "admin.banners.edit.delete-success": "Banner deleted",
  "admin.banners.edit.delete-title": "Deleting Banner",
  "admin.banners.edit.delete-description": "Are you sure you want to delete the banner?",
  "admin.welcome-messages.add.title": "Add welcome message",
  "admin.welcome-messages.add.name": "Name",
  "admin.welcome-messages.add.url": "URL",
  "admin.welcome-messages.add.description": "Welcome message",
  "admin.welcome-messages.add.start-date": "Start date",
  "admin.welcome-messages.add.end-date": "End date",
  "admin.welcome-messages.add.banner-visibility": "Visibility",
  "admin.welcome-messages.add.visible": "Visible",
  "admin.welcome-messages.add.invisible": "Invisible",
  "admin.welcome-messages.add.submit-button": "Add welcome message",
  "admin.welcome-messages.add.add-success": "Welcome message has been added",
  "admin.welcome-messages.add.add-action": "Add button",
  "admin.welcome-messages.add.remove-action": "Remove button",
  "admin.welcome-messages.add.action-name": "Name",
  "admin.welcome-messages.add.action-buttons": "Action buttons",
  "admin.welcome-messages.edit.title": "Edit",
  "admin.welcome-messages.edit.submit-button": "Save",
  "admin.welcome-messages.edit.update-success": "Welcome message updated",
  "admin.welcome-messages.edit.delete-success": "Welcome message deleted",
  "admin.welcome-messages.edit.delete-title": "Delete the welcome message",
  "admin.welcome-messages.edit.delete-description": "Are you sure you want to delete the welcome message?",
  "ROLE_ADMIN": "Admin",
  "ROLE_SALESPERSON": "Salesperson",
  "ROLE_SALESPERSON_POS": "Salesperson POS",
  "ROLE_USER": "User",
  "user.not.found": "User not found",
  "cart.random-products.title": "Featured products",
  "cart.back": "Continue shopping",
  "cart.title": "Cart items",
  "cart.list.label.price": "Net price",
  "cart.list.label.qty": "Quantity",
  "cart.list.label.price-total": "Total net price",
  "cart.list.label.vat": "Vat",
  "cart.list.label.price-gross-total": "Total price brutton",
  "cart.summary.title": "Summary",
  "cart.summary.price-netto": "Net price:",
  "cart.summary.price-vat": "VAT:",
  "cart.summary.price-total": "Total price:",
  "cart.summary.external-document": "External document no",
  "cart.empty.title": "Your shopping cart is empty",
  "cart.form.title": "Finalize order",
  "cart.form.date.placeholder": "Select a delivery date",
  "cart.form.method.placeholder": "Select a delivery method",
  "cart.form.notes.placeholder": "Comments to order",
  "cart.form.submit.btn": "Submit your order",
  "cart.products.added.1": "1 product added to cart",
  "cart.products.added.2-4": "{{qty}} products added to cart",
  "cart.products.added.5+": "{{qty}} products added to cart",
  "cart.products.removed.1": "1 product removed from cart",
  "cart.products.removed.2-4": "{{qty}} products removed from cart",
  "cart.products.removed.5+": "{{qty}} products removed from cart",
  "cart.order.success": "Your order has been placed",
  "cart.order.error": "An error occurred while placing your order",
  "cart.success.title": "Cart",
  "cart.success.description": "Your order has been placed.",
  "cart.button.back": "Continue shopping",
  "cart.suggested.btn": "Alternative product available",
  "cart.suggested.popup.title": "Do you want to add the product despite insufficient amount? Do you want to check the available substitutes of that product?",
  "payment.method.transport": "Delivery",
  "payment.method.pickup": "Self pick",
  "uploader.browse": "browse",
  "dropdown.placeholder.select": "Select",
  "dropdown.placeholder.select-address": "Select address",
  "dropdown.placeholder.select-category": "Select category",
  "dropdown.placeholder.select-variety": "Select variety",
  "calendar.weekday.0": "Mon",
  "calendar.weekday.1": "Tues",
  "calendar.weekday.2": "Wed",
  "calendar.weekday.3": "Thurs",
  "calendar.weekday.4": "Fri",
  "calendar.weekday.5": "Sat",
  "calendar.weekday.6": "Sun",
  "calendar.month.0": "January",
  "calendar.month.1": "February",
  "calendar.month.2": "March",
  "calendar.month.3": "April",
  "calendar.month.4": "May",
  "calendar.month.5": "June",
  "calendar.month.6": "July",
  "calendar.month.7": "August",
  "calendar.month.8": "September",
  "calendar.month.9": "October",
  "calendar.month.10": "November",
  "calendar.month.11": "December",
  "calendar.placeholder.input": "Select date",
  "documents.menu.sales": "Sales documents",
  "documents.menu.financial": "Financial documents",
  "documents.menu.liabilities": "Overdue liabilities",
  "documents.table.title.sales": "Sales documents",
  "documents.table.title.financial": "Financial documents",
  "documents.table.title.liabilities": "Overdue liabilities",
  "documents.table.header.type": "Type of document",
  "documents.table.header.date": "Order date",
  "documents.table.header.number": "Document number",
  "documents.table.header.last_shipping_no": "Shipping number",
  "documents.table.header.invoice_no": "Posted invoice number",
  "documents.table.header.price": "Sum",
  "documents.table.shipping_phone_no": "Supplier's phone number:",
  "documents.open-pdf.error": "Unable to download document details",
  "documents.payments.pay": "Pay",
  "documents.payments.sum": "Sum to be paid:",
  "documents.payments.processing": "The payment is being processed...",
  "documents.payments.cancel": "Cancel payment",
  "financial-documents.table.header.select": "Select",
  "financial-documents.table.header.type": "Document type",
  "financial-documents.table.header.number": "Document no",
  "financial-documents.table.header.date": "Document date",
  "financial-documents.table.header.original-amount": "Invoice amount",
  "financial-documents.table.header.remaining-amount": "Remaining amount",
  "financial-documents.table.header.due-date": "Due date",
  "financial-documents.table.return-product": "Return",
  "datepicker.clear": "Clear selection",
  "ROLE_USER_LIMITED": "User limited",
  "footer.contact.title": "Contact",
  "footer.contact.share-capital": "Amount of share capital:",
  "footer.shop": "Italian Academy of Chefs Mille Sapori IF&B",
  "footer.description": "The Italian Chef Academy is part of Mille Sapori Plus IF&B. The Academy organizes training for professionals as well as for enthusiasts of Italian flavors. We would like to share the acquired knowledge with chefs, future cooks, restaurant owners and enthusiasts of Italian flavors. In addition, we arrange culinary shows, tastings and meetings with producers.\n\nPlease contact us.",
  "footer.links.title": "Links",
  "footer.links.info-clause": "Information clause",
  "footer.links.privacy-policy": "Privacy policy",
  "polish": "Polish",
  "english": "English",
  "deutsch": "Deutsch",
  "name-and-lastname": "Name and Surname",
  "id-number": "Identification number",
  "select-salesperson-role": "Select salesperson roles",
  "select-cities": "Select cities",
  "select-lang": "Select language",
  "select-company": "Select company",
  "activity": "Activity",
  "btn.switch-user": "Change customer",
  "btn.profile": "Profile",
  "btn.logout": "Logout",
  "btn.back": "Back",
  "concessions.auth-ends-title": "Dear Customer, We kindly inform you that your license expires in a few days: ",
  "concessions.payment-ends-title": "Dear Customer, We kindly inform you that in a few days the payment deadline for your use of the permit expires: ",
  "concessions.beer": "sale of alcoholic beverages up to 4.5% alcohol and beer",
  "concessions.spirit": "sale of alcoholic beverages above 18%",
  "concessions.wine-spririts-to-18": "sale of alcoholic beverages above 4.5% up to 18% except beer",
  "concessions.description": "Send confirmation of the permit fee to your Sales Advisor",
  "blockades.Invoice.title": "Dear Customer,",
  "blockades.Invoice.description": "Due to arrears, your account has been suspended. Go to the <b>Total Overdue</b> window on the Main Panel where you will find details about overdue invoices. Once the arrears are settled, your account will be suspended.",
  "blockades.Invoice.description-limited": "Due to arrears, your account has been suspended for placing orders. In case of doubt, please contact your Sales Advisor.",
  "blockades.Ship.title": "Dear Customer,",
  "blockades.Ship.description": "Due to arrears, your account has been suspended. Go to the <b>Total Overdue</b> window on the Main Panel where you will find details about overdue invoices. Once the arrears are settled, your account will be suspended.",
  "blockades.Ship.description-limited": "Due to arrears, your account has been suspended for placing orders. In case of doubt, please contact your Sales Advisor.",
  "blockades.limit.title": "Dear Customer,",
  "blockades.limit.description": "Due to exceeding the <b>Merchant Limit</b>, placing another order is currently not possible. Please settle the oldest invoices in order to reduce the <b>Current Balance</b>. Details can be found on the Main Panel in the window <b>Your balance is</b> or in <b>Total Outstanding</b>.",
  "categories.title": "Categories list",
  "categories.search": "Search for category",
  "categories.no-categories": "No categories",
  "language.en-EN": "EN",
  "language.de-DE": "DE",
  "language.pl-PL": "PL",
  "language.es-ES": "ES",
  "currency.PLN": "{{ value }} PLN",
  "currency.EUR": "{{ value }} EUR",
  "document-preview.title.sales": "Sale document:",
  "document-preview.title.latest-sales": "Sale document:",
  "document-preview.title.financial": "Document number:",
  "document-preview.title.liabilities": "Document number:",
  "document-preview.download": "Download document",
  "document-preview.back.sales": "Back to sales order list",
  "document-preview.back.latest-sales": "Back to sales order list",
  "document-preview.back.financial": "Back to financial documents list",
  "document-preview.back.liabilities": "Back to overdue liabilities list",
  "document-edit.title": "Edit order:",
  "document-edit.submit": "Approve changes",
  "document-edit.success": "Changes have been saved",
  "document-edit.error": "Failed to save changes",
  "document-edit.warning": "The price may change after saving the order, due to the recalculation of discounts",
  "document-edit.empty-order": "The order is empty",
  "return-product.title": "Return products",
  "return-product.submit": "Return products",
  "return-product.choose-reason": "Select return reason",
  "return-product.select-product": "Select the products you wish to return"
}
