import { Options } from '../options';

export const options: Options = {
    logo: 'assets/images/logo.svg',
    loginBackground: 'assets/images/form-login-background.webp',
    imagesPath: 'index/rami/',
    productDiscounts: [
        {
            id: 'any',
            value: '',
        },
        {
            id: 'promotions',
            value: 'promotions',
        },
        {
            id: 'novelties',
            value: 'novelties',
        },
    ],
    dashboardImage: 'assets/images/dashboard-illustration.svg',
    currency: 'PLN',
    promoImage: 'assets/images/promo.svg',
    roles: ['ROLE_ADMIN', 'ROLE_SALESPERSON', 'ROLE_USER_LIMITED', 'ROLE_USER'],
    roleFeatures: {
        ROLE_ADMIN: [
            'products.table.image',
            'products.table.description',
            'products.table.no',
            'products.table.price',
            'products.table.discount',
            'products.table.discounted-price',
            'products.table.vat',
            'products.table.unit',
            'products.table.quantity',
            'products.table.on-stock',
            'products.table.actions',

            'latest-documents.table.order-date',
            'latest-documents.table.number',
            'latest-documents.table.invoice-no',
            'latest-documents.table.last-shipping-no',
            'latest-documents.table.actions',
            'latest-documents.table.total-amount',

            'nav.dashboard',
            'nav.products',
            'nav.documents',

            'show-prices',
        ],
        ROLE_SALESPERSON: [
            'products.table.image',
            'products.table.description',
            'products.table.no',
            'products.table.price',
            'products.table.discount',
            'products.table.discounted-price',
            'products.table.vat',
            'products.table.unit',
            'products.table.quantity',
            'products.table.on-stock',
            'products.table.actions',

            'latest-documents.table.order-date',
            'latest-documents.table.number',
            'latest-documents.table.invoice-no',
            'latest-documents.table.last-shipping-no',
            'latest-documents.table.actions',
            'latest-documents.table.total-amount',

            'nav.dashboard',
            'nav.products',
            'nav.documents',

            'show-prices',

            'customer.table.icon',
            'customer.table.no',
            'customer.table.vat-no',
            'customer.table.name',
            'customer.table.blocked',
            'customer.table.display-as',

            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
        ],
        ROLE_USER: [
            'products.table.image',
            'products.table.description',
            'products.table.no',
            'products.table.price',
            'products.table.discount',
            'products.table.discounted-price',
            'products.table.vat',
            'products.table.unit',
            'products.table.quantity',
            'products.table.actions',

            'latest-documents.table.order-date',
            'latest-documents.table.number',
            'latest-documents.table.invoice-no',
            'latest-documents.table.last-shipping-no',
            'latest-documents.table.actions',
            'latest-documents.table.total-amount',

            'nav.dashboard',
            'nav.products',
            'nav.documents',

            'show-prices',

            'financial-documents.payments',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
        ],
        ROLE_USER_LIMITED: [
            'products.table.image',
            'products.table.description',
            'products.table.no',
            'products.table.price',
            'products.table.discount',
            'products.table.discounted-price',
            'products.table.vat',
            'products.table.unit',
            'products.table.quantity',
            'products.table.actions',

            'latest-documents.table.order-date',
            'latest-documents.table.number',
            'latest-documents.table.invoice-no',
            'latest-documents.table.last-shipping-no',
            'latest-documents.table.actions',
            'latest-documents.table.total-amount',

            'nav.dashboard',
            'nav.products',
            'nav.documents',

            'show-prices',

            'financial-documents.payments',
            'financial-documents-table.document-type',
            'financial-documents-table.document-date',
            'financial-documents-table.document-no',
            'financial-documents-table.amount',
            'financial-documents-table.remaining-amount',
            'financial-documents-table.due-date',
            'financial-documents-table.actions',
        ],
    },
    salespersonRoles: ['Customer Service', 'Salesman', 'Wine Salesman', 'Director', 'Global Director', 'General Director'],
    salespersonRoleFeatures: {},
};
