{
  "change-password-success": "Das Passwort wurde geändert",
  "select": "Wählen",
  "preview": "Vorschau",
  "edit": "Bearbeiten",
  "payment-failed": "Zahlung fehlgeschlagen",
  "pending-payment-error": "Die Zahlung wird von einem anderen Benutzer ausgeführt. Bitte versuchen Sie es in ein paar Minuten erneut.",
  "pending-paid-alert": "Bitte beachten Sie, dass das Dokument {{documents}} derzeit von einem anderen Benutzer bearbeitet wird. Bitte versuchen Sie es in ein paar Minuten erneut",
  "copyright": "Copyright © 2024. All Rights Reserved.",
  "select-address-info": "Bevor Sie den Bestellvorgang abschließen, bitte die korrekte Lieferadresse auswählen",
  "user-blocked": "Benutzer ist gesperrt",
  "back": "Zurück",
  "more": "Mehr",
  "details": "Details",
  "gross": "Brutto",
  "search": "Suche",
  "filters": "Filterkriterien",
  "title": "Utsch",
  "active": "Aktiv",
  "unactive": "Inaktiv",
  "loading": "Ladevorgang...",
  "cart-name": "Warenkorb",
  "quantity": "Menge",
  "table.no-content": "Kein Inhalt",
  "table.loading": "Ladevorgang...",
  "table.sort": "Sortierung",
  "table.asc": "Aufsteigend",
  "table.desc": "Absteigend",
  "header.login": "Anmelden",
  "header.account-menu.change-password": "Passwort ändern",
  "header.account-menu.logout": "Abmelden",
  "header.nav.dashboard": "Dashboard",
  "header.nav.products": "Produkte",
  "header.nav.documents": "Dokumente",
  "header.nav.categories": "Kategorien",
  "login.welcome": "Herzlich willkommen auf unserem B2B-Portal!",
  "login.provide-credentials": "Bitte Benutzer und Passwort eingeben:",
  "login.title": "Login",
  "login.forgot-password": "Passwort vergessen?",
  "login.forgot-password-click-here": "Passwort vergessen? Bitte hier klicken:",
  "login.recover-password": "Passwort wiederherstellen",
  "login.input.email": "E-mail Adresse*",
  "login.input.password": "Passwort*",
  "login.cta.sign-in": "Anmelden",
  "login.cta.remind-now": "Merken",
  "login.cta.remember-login-data": "Login-Daten merken",
  "reset-password.title": "Passwort zurücksetzen",
  "reset-password.remember-password": "Passwort merken?",
  "reset-password.input.email": "E-mail Adresse*",
  "reset-password.cta.reset": "Passwort zurücksetzen",
  "reset-password.cta.sign-in": "Anmelden",
  "products.filters": "Angebote",
  "products.title": "Artikelübersicht",
  "products.open-card.error": "Produktansicht konnte nicht geladen werden",
  "products.table.filters.price": "Artikelpreis",
  "products.table.filters.discounts.any": "Rabatt auswählen",
  "products.table.filters.discounts.promotions": "Angebot",
  "products.table.filters.discounts.novelties": "Neuheiten",
  "products.table.filters.checkbox-category.recently": "Kürzlich bestellt",
  "products.table.filters.checkbox-category.less-frequently": "Seltener bestellt",
  "products.table.header.product-name": "Artikelbezeichnung",
  "products.table.header.product-number": "Artikelnr.",
  "products.table.header.list-price": "Listenpreis",
  "products.table.header.discount": "Rabatt",
  "products.table.header.discounted-price": "Nettopreis",
  "products.table.header.vat": "MwSt",
  "products.table.header.unit": "Einheit",
  "products.table.header.quantity": "Menge",
  "products.table.header.stock-availability": "Lagerverfügbarkeit",
  "products.table.header.availability": "Verfügbarkeit",
  "products.table.pcs": "Stück.",
  "products.table.large-amount": "Große Menge",
  "products.table.expected-delivery-date": "Erwartetes Lieferdatum:",
  "products.table.confirmed-delivery-date": "Bestätigtes Lieferdatum:",
  "products.table.expected-quantity": "Erwartete Menge:",
  "products.details.back": "zurück",
  "products.details.add-to-cart": "In den Warenkorb legen",
  "products.details.added-to-cart": "In den Warenkorb gelegt",
  "products.details.stock-availability": "Lagerverfügbarkeit",
  "products.details.availability": "Verfügbarkeit",
  "products.details.manufacturer-code": "Hersteller",
  "products.details.description": "Artikelbeschreibung",
  "products.details.open-card": "Artikelkarte",
  "products.favourites.button": "Favoriten",
  "products.discounts-popup.title": "Rabatt!",
  "products.discounts-popup.description": "Wenn Sie mehr von diesem Artikel kaufen, dann bekommen Sie einen besseren Preis!",
  "products.discounts-popup.old-price": "Letzter Preis",
  "products.discounts-popup.regular-price": "Akueller Preis",
  "products.discounts-popup.promo-price": "Spezialpreis",
  "products.discounts-popup.min-pcs": "mind. {{pcs}} Stk",
  "products.discounts-popup.no-discount": "Kein Rabatt verfügbar",
  "favourites.added-to-favourites": "Zu Favoriten hinzufügen",
  "favourites.removed-from-favourites": "Aus Favoriten löschen",
  "favourites.title": "Favoriten",
  "favourites.products.button": "Alle Artikel",
  "set-password.title": "Passwort ändern",
  "set-password.input.password": "Passwort",
  "set-password.input.repeat-password": "Wiederholung des neuen Passworts",
  "set-password.cta.reset": "Passwort zurücksetzen",
  "customers.title": "Liste der Einkäufer",
  "customers.table.header.customer-id": "Käufer ID",
  "customers.table.header.company-name": "Firmenname",
  "customers.table.header.second-name": "Name 2",
  "customers.table.header.restaurant-name": "Restaurant Name",
  "customers.table.header.blocked": "Blockiert",
  "customers.table.header.city": "Stadt",
  "customers.table.header.display-as": "Anzeige als",
  "customers.table.header.vat-registration-no": "Steuernr.",
  "customers.blocked.All": "Alle",
  "customers.blocked.Invoice": "Rechnung",
  "customers.blocked.Ship": "Schiff",
  "customers.blocked.Vindication": "Bestätigung",
  "forms.error.message.date-range": "Ungültiger Datumsbereich",
  "forms.error.message.required": "Dieses Feld ist erforderlich",
  "forms.error.message.postal-code": "Ungültige Postleitzahl",
  "forms.error.message.invalid_address": "Geben Sie den Namen der Stadt, die Straße und die Hausnummer ein",
  "forms.error.message.invalid_street": "Geben Sie den Straßennamen und die Hausnummer ein",
  "forms.error.message.invalid_street_number": "Eingabe der Hausnummer",
  "forms.error.message.new_password": "Das neue Passwort muss in beiden Feldern gleich lauten",
  "forms.error.message.max_length": "Maximale Anzahl der Zeichen überschritten",
  "forms.error.message.min_length": "Mindestanzahl von Zeichen: {{min_length}}",
  "forms.error.message.max": "Maximaler Wert: {{max}}",
  "forms.error.message.invalid_url": "Invalid url",
  "forms.error.message.general": "Ungültige Url",
  "forms.error.message.at_least_one_capital": "Das Feld sollte einen Großbuchstaben enthalten",
  "forms.error.message.at_least_one_lower": "Das Feld sollte einen Kleinbuchstaben enthalten",
  "forms.error.message.at_least_one_number": "Das Feld sollte eine Zahl enthalten",
  "forms.error.message.at_least_one_symbol": "Das Feld sollte ein Symbol enthalten - !@#$%^&*()",
  "forms.price-range.from": "Von",
  "forms.price-range.to": "Bis",
  "forms.dropdown.placeholder": "Wert auswählen",
  "forms.dropdown.placeholder-all": "Alle",
  "dashboard.welcome.welcome": "Willkommen",
  "dashboard.welcome.message-1": "  Willkommen im neuen Portal, mit dem Sie viel schneller eine Bestellung aufgeben können!",
  "dashboard.welcome.message-2": "Hier haben Sie Zugriff auf alle Utsch-Produkte!",
  "dashboard.welcome.message-3": "Zertifizierte deutsche Produkte von höchster Qualität auf Knopfdruck!",
  "dashboard.welcome.view-products": "Produkte ansehen",
  "dashboard.welcome.activity": "Aktivität",
  "dashboard.balance.your-balance": "Ihr Guthaben ist",
  "dashboard.balance.credit-limit": "Ihr Kreditlimit ist",
  "dashboard.balance.liabilities": "Summe aller Verbindlichkeiten",
  "dashboard.balance.full-info": "Vollständige Informationen",
  "dashboard.balance.price-gross": "Bruttopreis",
  "dashboard.balance.sales-info.seller": "Ihr Verkaufsberater",
  "dashboard.balance.sales-info.write-now": "Jetzt schreiben",
  "dashboard.latest-documents.title": "Aktuelle Verkaufsunterlagen",
  "dashboard.latest-documents.all-documents": "Alle Dokumente",
  "dashboard.buyer-details": "Details zum Käufer",
  "dashboard.payment-method": "Zahlungsmethode",
  "cl.account.login-success": "Erfolgreich eingeloggt",
  "cl.account.login-invalid-credentials": "Falsches Passwort oder falsche E-Mail Adresse",
  "cl.account.account_not_active": "Das Konto ist nicht aktiviert",
  "cl.account.eip_user_blocked_reason_all": "Das Konto wurde gesperrt. Bitte kontaktieren Sie Ihren Administrator",
  "cl.account.login-not-activated": "Konto wurde nicht aktiviert",
  "cl.account.logout-success": "Sie haben sich erfolgreich abgemeldet",
  "cl.account.logout-error": "Fehler beim Abmelden",
  "cl.account.reset-password-request-success": "E-Mail zum Zurücksetzen des Passworts gesendet",
  "cl.account.reset-password-request-error": "Bei der Bearbeitung der Anfrage ist ein Fehler aufgetreten",
  "cl.account.reset-password-success": "Das Passwort wurde aktualisiert",
  "cl.account.reset-password-error": "Der Link zum Zurücksetzen des Passworts ist ungültig",
  "cl.account.activate-success": "Das Konto wurde aktiviert",
  "cl.account.activate-error": "Beim Aktivieren Ihres Kontos ist ein Fehler aufgetreten",
  "cl.account.resend-activation-link-success": "Der Aktivierungslink wurde gesendet",
  "cl.account.resend-activation-link-error": "Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten",
  "profile.account-settings.title": "Kontoeinstellungen",
  "profile.account-settings.change-password": "Passwort ändern",
  "profile.account-settings.current-password": "Aktuelles Passwort",
  "profile.account-settings.new-password": "Neues Passwort",
  "profile.account-settings.repeat-password": "Wiederholung des neuen Passworts",
  "admin.nav.users-list": "Benutzerliste",
  "admin.nav.notifications": "Benachrichtigungen",
  "admin.nav.banners": "Banner",
  "admin.nav.welcome-messages": "Willkommensnachrichten",
  "admin.nav.settings": "Portaleinstellungen",
  "admin.table.users.title": "Liste der Benutzer",
  "admin.table.users.add-new-user-button": "Neuen Benutzer hinzufügen",
  "admin.table.users.salesperson": "Vertriebsmitarbeiter:",
  "admin.table.users.deputy": "Stellvertreter:",
  "admin.table.users.header.customer-id": "Kundennr.",
  "admin.table.users.header.email": "Email Adresse",
  "admin.table.users.header.role": "Rolle",
  "admin.table.users.header.activity": "Aktivität",
  "admin.table.users.header.salesperson": "Vertriebsmitarbeiter",
  "admin.table.users.filters.select-role": "Rollen auswählen",
  "admin.table.users.filters.select-activity": "Aktivität auswählen",
  "admin.table.user-logs.title": "Benutzeraktivität",
  "admin.table.user-logs.header.log-date": "Datum der Anmeldung",
  "admin.table.user-logs.header.status": "Status",
  "admin.table.user-logs.status.user-create": "Benutzer erstellt",
  "admin.table.user-logs.status.user-fail-logged": "Benutzeranmeldung fehlgeschlagen",
  "admin.table.user-logs.status.user-locked": "Benutzer gesperrt",
  "admin.table.user-logs.status.user-success-logged": "Anmeldung erfolgreich abgeschlossen",
  "admin.table.user-logs.status.user-update": "Benutzer-Update",
  "admin.table.user-logs.status.user-delete": "Benutzer gelöscht",
  "admin.table.user-logs.status.generate-password": "Passwort generiert",
  "admin.table.user-logs.status.user-success-password-change": "Benutzerkennwort erfolgreich geändert",
  "admin.table.user-logs.status.user-fail-password-change": "Versäumnis, das Benutzerpasswort zu ändern",
  "admin.table.user-logs.status.user-unlocked": "Benutzer entriegelt",
  "admin.table.user-logs.status.user-notification-create": "Benachrichtigung erstellt",
  "admin.table.user-logs.status.user-notification-delete": "Benachrichtigung gelöscht",
  "admin.table.user-logs.status.user-notification-update": "Aktualisierung der Benachrichtigung",
  "admin.table.user-logs.status.user-welcome-message-create": "Willkommensnachricht erstellt",
  "admin.table.user-logs.status.user-welcome-message-delete": "Willkommensnachricht gelöscht",
  "admin.table.user-logs.status.user-welcome-message-update": "Aktualisierung der Willkommensnachricht",
  "admin.table.user-logs.status.user-banner-create": "Banner erstellt",
  "admin.table.user-logs.status.user-banner-delete": "Banner gelöscht",
  "admin.table.user-logs.status.user-banner-update": "Banner-Update",
  "admin.table.user-logs.status.user-success-order": "Bestellung erfolgreich",
  "admin.table.user-logs.status.user-fail-order": "Bestellung fehlgeschlagen",
  "admin.table.notifications.title": "Benachrichtigungsliste",
  "admin.table.notifications.create-new-notifications-button": "Neue Ausschreibung erstellen",
  "admin.table.notifications.header.title": "Titel",
  "admin.table.notifications.header.description": "Text",
  "admin.table.notifications.header.active-date-from": "Aktiv seit",
  "admin.table.notifications.header.active-date-to": "Aktiv bis",
  "admin.table.notifications.header.edit": "bearbeiten",
  "admin.table.banners.title": "Banner Liste",
  "admin.table.banners.create-new-banners-button": "Neues Banner erstellen",
  "admin.table.banners.header.title": "Titel",
  "admin.table.banners.header.active-date-from": "Aktiv seit",
  "admin.table.banners.header.active-date-to": "Aktiv bis zu",
  "admin.table.banners.header.edit": "bearbeiten",
  "admin.table.welcome-messages.title": "Liste der Willkommensnachrichten",
  "admin.table.welcome-messages.create-new-button": "Neue Nachricht erstellen",
  "admin.table.welcome-messages.header.name": "Name",
  "admin.table.welcome-messages.header.description": "Nachricht",
  "admin.table.welcome-messages.header.date-from": "Aktiv von",
  "admin.table.welcome-messages.header.date-to": "Aktiv an",
  "admin.table.welcome-messages.header.active": "Sichtbarkeit",
  "admin.table.welcome-messages.header.edit": "bearbeiten",
  "admin.users.title": "Verwaltung von {{email}}",
  "admin.users.nav.edit": "bearbeiten",
  "admin.users.nav.activity": "Aktivität",
  "admin.users.add.title": "Hinzufügen von Benutzern",
  "admin.users.add.set-role": "Rolle festlegen",
  "admin.users.add.add-user": "Benutzer hinzufügen",
  "admin.users.add.back": "Zurück",
  "admin.users.add.user-has-been-added": "Benutzer wurde hinzugefügt",
  "admin.users.add.errors.email.exists": "Diese E-Mail existiert bereits.",
  "admin.users.add.errors.eip.customer_user_email_not_found": "Kundenbenutzer mit dieser E-Mail-Adresse konnte in BC nicht gefunden werden.",
  "admin.users.edit.title": "Benutzer bearbeiten",
  "admin.users.edit.set-role": "Rolle festlegen",
  "admin.users.edit.save": "Speichern",
  "admin.users.edit.back": "Zurück",
  "admin.users.edit.user-has-been-added": "Der Benutzer wurde aktualisiert",
  "admin.users.edit.update-success": "Benutzer aktualisiert",
  "admin.users.edit.delete-success": "Benutzer gelöscht",
  "admin.users.edit.delete-title": "Benutzer löschen",
  "admin.users.edit.delete-description": "Sind Sie sicher, dass Sie den Benutzer löschen wollen?",
  "admin.users.edit.delete-yes": "Ja",
  "admin.users.edit.delete-no": "Nein",
  "admin.users.edit.blocked": "Gesperrt",
  "admin.users.edit.unblocked": "Nicht gesperrt",
  "admin.users.edit.delete": "Löschen",
  "admin.users.edit.change-password": "Passwort ändern",
  "admin.users.edit.change-password-title": "Sind Sie sicher, dass Sie das Passwort für diesen Benutzer zurücksetzen möchten?",
  "admin.users.edit.reset-password": "Passwort zurücksetzen",
  "admin.users.edit.change-password-success": "Das Passwort des Benutzers wurde erfolgreich generiert",
  "admin.users.edit.errors.email.exists": "Diese E-Mail existiert bereits.",
  "admin.notifications.add.title": "Benachrichtigung hinzufügen",
  "admin.notifications.add.name": "Name",
  "admin.notifications.add.description": "Beschreibung",
  "admin.notifications.add.start-date": "Startdatum",
  "admin.notifications.add.end-date": "Enddatum",
  "admin.notifications.add.choose-date": "Datum wählen",
  "admin.notifications.add.submit-button": "Benachrichtigung hinzufügen",
  "admin.notifications.add.visible": "Sichtbar",
  "admin.notifications.add.invisible": "Unsichtbar",
  "admin.notifications.add.alert-visibility": "Sichtbarkeit der Benachrichtigung",
  "admin.notifications.add.notification-has-been-added": "Benachrichtigung wurde hinzugefügt",
  "admin.notifications.edit.title": "Bearbeiten {{notification}}",
  "admin.notifications.edit.update-success": "Benachrichtigung aktualisiert",
  "admin.notifications.edit.delete-success": "Benachrichtigung gelöscht",
  "admin.notifications.edit.save": "Speichern",
  "admin.notifications.edit.delete-title": "Löschen der Meldung",
  "admin.notifications.edit.delete-description": "Sind Sie sicher, dass Sie die Benachrichtigung löschen möchten?",
  "admin.banners.add.title": "Banner hinzufügen",
  "admin.banners.add.name": "Titel",
  "admin.banners.add.url": "URL",
  "admin.banners.add.start-date": "Startdatum",
  "admin.banners.add.end-date": "Enddatum",
  "admin.banners.add.image": "Foto",
  "admin.banners.add.banner-visibility": "Sichtbarkeit der Banner",
  "admin.banners.add.visible": "Sichtbar",
  "admin.banners.add.invisible": "Unsichtbar",
  "admin.banners.add.submit-button": "Banner hinzufügen",
  "admin.banners.add.banner-has-been-added": "Banner wurde hinzugefügt",
  "admin.banners.edit.title": "Bearbeiten {{banner}}",
  "admin.banners.edit.submit-button": "Speichern",
  "admin.banners.edit.update-success": "Banner aktualisiert",
  "admin.banners.edit.delete-success": "Banner gelöscht",
  "admin.banners.edit.delete-title": "Banner löschen",
  "admin.banners.edit.delete-description": "Sind Sie sicher, dass Sie das Banner löschen wollen?",
  "admin.welcome-messages.add.title": "Willkommensnachricht hinzufügen",
  "admin.welcome-messages.add.name": "Name",
  "admin.welcome-messages.add.url": "URL",
  "admin.welcome-messages.add.description": "Willkommensnachricht",
  "admin.welcome-messages.add.start-date": "Startdatum",
  "admin.welcome-messages.add.end-date": "Enddatum",
  "admin.welcome-messages.add.banner-visibility": "Sichtbarkeit",
  "admin.welcome-messages.add.visible": "Sichtbar",
  "admin.welcome-messages.add.invisible": "Unsichtbar",
  "admin.welcome-messages.add.submit-button": "Willkommensnachricht hinzufügen",
  "admin.welcome-messages.add.add-success": "Willkommensnachricht wurde hinzugefügt",
  "admin.welcome-messages.add.add-action": "Schaltfläche hinzufügen",
  "admin.welcome-messages.add.remove-action": "Schaltfläche entfernen",
  "admin.welcome-messages.add.action-name": "Name",
  "admin.welcome-messages.add.action-buttons": "Aktionsschaltflächen",
  "admin.welcome-messages.edit.title": "bearbeiten",
  "admin.welcome-messages.edit.submit-button": "Speichern",
  "admin.welcome-messages.edit.update-success": "Die Willkommensnachricht wurde aktualisiert",
  "admin.welcome-messages.edit.delete-success": "Willkommensnachricht gelöscht",
  "admin.welcome-messages.edit.delete-title": "Löschen der Willkommensnachricht",
  "admin.welcome-messages.edit.delete-description": "Sie sollten die Willkommensnachricht auf jeden Fall löschen?",
  "admin.settings.title": "Portal Einstellungen",
  "admin.settings.select-default-category": "Standardkategorie auswählen",
  "admin.settings.save": "Speiche",
  "admin.settings.success": "Änderungen wurden gespeichert",
  "admin.settings.error": "Änderungen konnten nicht gespeichert werden",
  "ROLE_ADMIN": "Verwaltung",
  "ROLE_SALESPERSON": "Vertriebsmitarbeiter",
  "ROLE_SALESPERSON_POS": "Vertriebsmitarbeiter POS",
  "ROLE_USER": "Benutzer",
  "user.not.found": "Benutzer nicht gefunden",
  "cart.random-products.title": "Ausgewählte Produkte",
  "cart.back": "Weiter einkaufen",
  "cart.title": "Artikel im Warenkorb",
  "cart.list.label.price": "Nettopreis",
  "cart.list.label.qty": "Menge",
  "cart.list.label.price-total": "Netto-Gesamtpreis",
  "cart.list.label.vat": "MwSt",
  "cart.list.label.price-gross-total": "Gesamtpreis brutto",
  "cart.summary.title": "Zusammenfassung",
  "cart.summary.price-netto": "Nettopreis:",
  "cart.summary.price-vat": "MWST.:",
  "cart.summary.price-total": "Gesamtpreis:",
  "cart.summary.external-document": "Externe Dokumentennr",
  "cart.empty.title": "Ihr Einkaufswagen ist leer",
  "cart.form.title": "Bestellung abschließen",
  "cart.form.date.placeholder": "Wählen Sie ein Lieferdatum",
  "cart.form.method.placeholder": "Wählen Sie eine Zustellmethode",
  "cart.form.notes.placeholder": "Kommentare zur Bestellung",
  "cart.form.submit.btn": "Bestellung abschicken",
  "cart.products.added.1": "1 Produkt in den Warenkorb gelegt",
  "cart.products.added.2-4": "{{qty}} Produkte zum Warenkorb hinzugefügt",
  "cart.products.added.5+": "{{qty}} Produkte zum Warenkorb hinzugefügt",
  "cart.products.removed.1": "1 Produkt aus dem Warenkorb entfernt",
  "cart.products.removed.2-4": "{{qty}} Produkte aus dem Warenkorb entfernt",
  "cart.products.removed.5+": "{{qty}} Produkte aus dem Warenkorb entfernt",
  "cart.order.success": "Ihre Bestellung ist aufgegeben worden",
  "cart.order.error": "Bei Ihrer Bestellung ist ein Fehler aufgetreten",
  "cart.success.title": "Warenkorb",
  "cart.success.description": "Ihre Bestellung ist aufgegeben worden.",
  "cart.button.back": "Weiter einkaufen",
  "cart.suggested.btn": "Alternatives Produkt verfügbar",
  "cart.suggested.popup.title": "Möchten Sie das Produkt trotz unzureichender Menge hinzufügen? Möchten Sie die verfügbaren Ersatzprodukte für dieses Produkt prüfen?",
  "payment.method.transport": "Lieferung",
  "payment.method.pickup": "Selbst wählen",
  "uploader.browse": "durchsuchen",
  "dropdown.placeholder.select": "Wählen Sie",
  "dropdown.placeholder.select-address": "Adresse auswählen",
  "dropdown.placeholder.select-category": "Kategorie auswählen",
  "dropdown.placeholder.select-variety": "Sorte auswählen",
  "calendar.weekday.0": "Mo",
  "calendar.weekday.1": "Di",
  "calendar.weekday.2": "Mi",
  "calendar.weekday.3": "Do",
  "calendar.weekday.4": "Fr",
  "calendar.weekday.5": "Sa",
  "calendar.weekday.6": "So",
  "calendar.month.0": "Januar",
  "calendar.month.1": "Februar",
  "calendar.month.2": "März",
  "calendar.month.3": "April",
  "calendar.month.4": "Mai",
  "calendar.month.5": "Juni",
  "calendar.month.6": "Juli",
  "calendar.month.7": "August",
  "calendar.month.8": "September",
  "calendar.month.9": "Oktober",
  "calendar.month.10": "November",
  "calendar.month.11": "Dezember",
  "calendar.placeholder.input": "Datum auswählen",
  "documents.menu.sales": "Verkaufsunterlagen",
  "documents.menu.financial": "Finanzielle Dokumente",
  "documents.menu.liabilities": "Überfällige Verbindlichkeiten",
  "documents.table.title.sales": "Verkaufsunterlagen",
  "documents.table.title.financial": "Finanzielle Dokumente",
  "documents.table.title.liabilities": "Überfällige Verbindlichkeiten",
  "documents.table.header.type": "Art des Dokuments",
  "documents.table.header.date": "Datum der Bestellung",
  "documents.table.header.number": "Nummer des Dokuments",
  "documents.table.header.last_shipping_no": "Versandnummer",
  "documents.table.header.invoice_no": "Gebuchte Rechnungsnummer",
  "documents.table.header.price": "Summe",
  "documents.table.shipping_phone_no": "Telefonnummer des Lieferanten:",
  "documents.open-pdf.error": "Details zum Dokument können nicht heruntergeladen werden",
  "documents.payments.pay": "Bezahlen",
  "documents.payments.sum": "Zu zahlender Betrag:",
  "documents.payments.processing": "Die Zahlung wird verarbeitet …",
  "documents.payments.cancel": "Zahlung stornieren",
  "financial-documents.table.header.select": "Wählen",
  "financial-documents.table.header.type": "Dokumententyp",
  "financial-documents.table.header.number": "Belegnummer",
  "financial-documents.table.header.date": "Belegdatum",
  "financial-documents.table.header.original-amount": "Rechnungsbetrag",
  "financial-documents.table.header.remaining-amount": "Verbleibender Betrag",
  "financial-documents.table.header.due-date": "Fälligkeitsdatum",
  "financial-documents.table.return-produc": "Zurückgeben",
  "datepicker.clear": "Auswahl löschen",
  "ROLE_USER_LIMITED": "Benutzer ist limitiert",
  "footer.contact.title": "Kontakt",
  "footer.contact.share-capital": "Höhe des Grundkapitals:",
  "footer.shop": "Utsch",
  "footer.description": "Utsch",
  "footer.links.title": "Links",
  "footer.links.info-clause": "Informationsklausel",
  "footer.links.privacy-policy": "Datenschutzbestimmungen",
  "polish": "Polnisch",
  "english": "Englisch",
  "deutsch": "Deutsch",
  "name-and-lastname": "Vorname und Nachname",
  "id-number": "Identifikationsnummer",
  "select-salesperson-role": "Verkäuferrollen auswählen",
  "select-cities": "Städte auswählen",
  "select-lang": "Sprache auswählen",
  "select-company": "Unternehmen auswählen",
  "activity": "Tätigkeit",
  "btn.switch-user": "Kunde ändern",
  "btn.profile": "Profil",
  "btn.logout": "Abmeldung",
  "btn.back": "Zurück",
  "concessions.auth-ends-title": "Sehr geehrter Kunde, wir möchten Sie darauf hinweisen, dass Ihre Lizenz in wenigen Tagen abläuft.: ",
  "concessions.payment-ends-title": "Sehr geehrter Kunde, wir möchten Sie darauf hinweisen, dass in wenigen Tagen die Zahlungsfrist für die Nutzung der Genehmigung abläuft: ",
  "concessions.beer": "Verkauf von alkoholischen Getränken mit bis zu 4,5 % Alkohol und Bier",
  "concessions.spirit": "Verkauf von alkoholischen Getränken über 18%",
  "concessions.wine-spririts-to-18": "Verkauf von alkoholischen Getränken mit einem Alkoholgehalt von über 4,5 % bis zu 18 %, ausgenommen Bier",
  "concessions.description": "Senden Sie eine Bestätigung der Genehmigungsgebühr an Ihren Verkaufsberater",
  "blockades.Invoice.title": "Lieber Kunde,",
  "blockades.Invoice.description": "Due to arrears, your account has been suspended. Go to the <b>Total Overdue</b> window on the Main Panel where you will find details about overdue invoices. Once the arrears are settled, your account will be suspended.",
  "blockades.Invoice.description-limited": "Aufgrund von Zahlungsrückständen wurde Ihr Konto für die Erteilung von Aufträgen gesperrt. Im Zweifelsfall wenden Sie sich bitte an Ihren Verkaufsberater.",
  "blockades.Ship.title": "Lieber Kunde,",
  "blockades.Ship.description": "Due to arrears, your account has been suspended. Go to the <b>Total Overdue</b> window on the Main Panel where you will find details about overdue invoices. Once the arrears are settled, your account will be suspended.",
  "blockades.Ship.description-limited": "Aufgrund von Zahlungsrückständen wurde Ihr Konto für die Erteilung von Aufträgen gesperrt. Im Zweifelsfall wenden Sie sich bitte an Ihren Verkaufsberater.",
  "blockades.limit.title": "Lieber Kunde,",
  "blockades.limit.description": "Due to exceeding the <b>Merchant Limit</b>, placing another order is currently not possible. Please settle the oldest invoices in order to reduce the <b>Current Balance</b>. Details can be found on the Main Panel in the window <b>Your balance is</b> or in <b>Total Outstanding</b>.",
  "categories.title": "Liste der Kategorien",
  "categories.search": "Suche nach Kategorie",
  "categories.no-categories": "Keine Kategorien",
  "language.en-EN": "EN",
  "language.de-DE": "DE",
  "language.pl-PL": "PL",
  "language.es-ES": "ES",
  "currency.PLN": "{{ value }} PLN",
  "currency.EUR": "{{ value }} EUR",
  "document-preview.title.sales": "Verkaufsdokument:",
  "document-preview.title.latest-sales": "Verkaufsdokument:",
  "document-preview.title.financial": "Belegnummer:",
  "document-preview.title.liabilities": "Belegnummer:",
  "document-preview.download": "Dokument herunterladen",
  "document-preview.back.sales": "Zurück zur Kundenauftragsliste",
  "document-preview.back.latest-sales": "Zurück zur Kundenauftragsliste",
  "document-preview.back.financial": "Zurück zur Liste der Finanzdokumente",
  "document-preview.back.liabilities": "Zurück zur Liste der überfälligen Verbindlichkeiten",
  "document-edit.title": "Auftrag bearbeiten:",
  "document-edit.submit": "Änderungen genehmigen",
  "document-edit.success": "Die Änderungen wurden gespeichert",
  "document-edit.error": "Änderungen konnten nicht gespeichert werden",
  "document-edit.warning": "Der Preis kann sich nach dem Speichern der Änderungen aufgrund der Neuberechnung der Rabatte ändern",
  "document-edit.empty-order": "Die Bestellung ist leer",
  "return-product.title": "Produkte zurückgeben",
  "return-product.submit": "Produkte zurückgeben",
  "return-product.choose-reason": "Rückgabegrund auswählen",
  "return-product.select-product": "Wählen Sie die Produkte aus, die Sie zurückgeben möchten"
}
