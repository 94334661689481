{
  "change-password-success": "Hasło zostało zmienione",
  "select": "Wybierz",
  "preview": "Podgląd",
  "edit": "Edytuj",
  "payment-failed": "Płatność nie powiodła się",
  "pending-payment-error": "Płatność jest w trakcie realizacji przez innego użytkownika. Spróbuj ponownie za kilka minut.",
  "pending-payment-alert": "Informujemy, że w tym momencie dokument {{documents}} jest w trakcie realizacji płatności przez innego użytkownika. Spróbuj ponownie za kilka minut",
  "copyright": "Copyright © 2024. Wszelkie Prawa Zastrzeżone.",
  "select-address-info": "Przed złożeniem zamówienia, wybierz właściwy adres dostawy",
  "user-blocked": "Użytkownik jest zablokowany",
  "back": "Powrót",
  "more": "Więcej",
  "details": "Szczegóły",
  "gross": "Brutto",
  "search": "Wyszukaj",
  "filters": "Filtrowanie",
  "loading": "Ładowanie...",
  "title": "Mille Sapori",
  "cart-name": "Koszyk",
  "quantity": "Ilość",
  "active": "Aktywny",
  "unactive": "Nieaktywny",
  "table.no-content": "Brak danych",
  "table.loading": "Ładowanie...",
  "table.sort": "Sortowanie",
  "table.asc": "rosnąco",
  "table.desc": "malejąco",
  "header.login": "Logowanie",
  "header.account-menu.change-password": "Zmień hasło",
  "header.account-menu.logout": "Wyloguj",
  "header.nav.dashboard": "Panel",
  "header.nav.products": "Produkty",
  "header.nav.documents": "Dokumenty",
  "header.nav.categories": "Kategorie",
  "login.welcome": "Witaj na naszym portalu!",
  "login.provide-credentials": "Wprowadź swoje dane do logowania poniżej:",
  "login.title": "Logowanie",
  "login.forgot-password": "Zapomniałeś hasła?",
  "login.forgot-password-click-here": "Zapomniałeś hasła? Kliknij tutaj:",
  "login.recover-password": "Odzyskaj hasło",
  "login.input.email": "Adres e-mail*",
  "login.input.password": "Hasło*",
  "login.cta.sign-in": "Zaloguj",
  "login.cta.remind-now": "Odzyskaj teraz",
  "login.cta.remember-login-data": "Zapamiętaj dane logowania",
  "reset-password.title": "Zapomniałem hasła",
  "reset-password.remember-password": "Znasz hasło?",
  "reset-password.input.email": "Adres e-mail*",
  "reset-password.cta.reset": "Resetuj hasło",
  "reset-password.cta.sign-in": "Zaloguj się",
  "products.filters": "Oferty Specjalne",
  "products.title": "Lista produktów",
  "products.open-card.error": "Nie udało się pobrać karty produktu",
  "products.table.filters.price": "Cena produktu",
  "products.table.filters.discounts.any": "Wybierz promocję",
  "products.table.filters.discounts.promotions": "PROMOCJE",
  "products.table.filters.discounts.novelties": "NOWOŚCI",
  "products.table.filters.checkbox-category.recently": "ostatnio zamawiane",
  "products.table.filters.checkbox-category.less-frequently": "rzadziej zamawiane",
  "products.table.header.product-name": "Nazwa produktu",
  "products.table.header.product-number": "Numer produktu",
  "products.table.header.list-price": "Cena katalogowa",
  "products.table.header.discount": "Rabat",
  "products.table.header.discounted-price": "Cena po rabacie",
  "products.table.header.vat": "VAT",
  "products.table.header.unit": "Jednostka miary",
  "products.table.header.quantity": "Ilość",
  "products.table.header.stock-availability": "Stan magazynowy",
  "products.table.header.availability": "Dostępność",
  "products.table.pcs": "szt.",
  "products.table.large-amount": "Duża ilość",
  "products.table.expected-delivery-date": "Spodziewana data dostawy:",
  "products.table.confirmed-delivery-date": "Potwierdzona data dostawy:",
  "products.table.expected-quantity": "Spodziewana ilość:",
  "products.details.back": "Powrót",
  "products.details.add-to-cart": "Dodaj do koszyka",
  "products.details.added-to-cart": "Dodano do koszyka",
  "products.details.stock-availability": "Stan magazynowy",
  "products.details.availability": "Dostępność",
  "products.details.manufacturer-code": "Kod producenta",
  "products.details.description": "Opis produktu",
  "products.details.open-card": "Karta produktu",
  "products.favourites.button": "Ulubione",
  "products.discounts-popup.title": "Promocja!",
  "products.discounts-popup.description": "Kupując więcej sztuk tego produktu otrzymujesz lepsze ceny jednostkowe (netto)!",
  "products.discounts-popup.old-price": "Ostatnia cena regularna",
  "products.discounts-popup.regular-price": "Aktualna cena promocyjna",
  "products.discounts-popup.promo-price": "Cena przy zakupie",
  "products.discounts-popup.min-pcs": "minimum {{pcs}} sztuk",
  "products.discounts-popup.no-discount": "Promocje nie są dostępne dla wybranej jednostki miary",
  "products.sidebar-filters.apply": "Zastosuj filtry",
  "products.sidebar-filters.clear": "Wyczyść filtry",
  "favourites.added-to-favourites": "Dodano do listy ulubionych",
  "favourites.removed-from-favourites": "Usunięto z listy ulubionych",
  "favourites.title": "Ulubione",
  "favourites.products.button": "Wszystkie produkty",
  "set-password.title": "Zmiana hasła",
  "set-password.input.password": "Hasło",
  "set-password.input.repeat-password": "Powtórz nowe hasło",
  "set-password.cta.reset": "Ustaw hasło",
  "customers.title": "Lista nabywców",
  "customers.table.header.customer-id": "ID klienta",
  "customers.table.header.company-name": "Nazwa firmy",
  "customers.table.header.second-name": "Nazwa 2",
  "customers.table.header.restaurant-name": "Wyróżnik",
  "customers.table.header.blocked": "Zablokowany",
  "customers.table.header.city": "Miasto",
  "customers.table.header.display-as": "Wyświetl jako",
  "customers.table.header.vat-registration-no": "NIP",
  "customers.blocked.All": "Wszystko",
  "customers.blocked.Invoice": "Faktura",
  "customers.blocked.Ship": "Zamówienia",
  "customers.blocked.Vindication": "Windykacja",
  "forms.error.message.date-range": "Zakres dat jest nieprawidłowy",
  "forms.error.message.required": "To pole jest wymagane",
  "forms.error.message.postal-code": "Niepoprawny kod pocztowy",
  "forms.error.message.invalid_address": "Podaj nazwę miejscowości, ulicy oraz numer domu",
  "forms.error.message.invalid_street": "Podaj nazwę ulicy oraz numer domu",
  "forms.error.message.invalid_street_number": "Podaj numer domu",
  "forms.error.message.new_password": "Nowe hasło musi być takie samo w obu polach",
  "forms.error.message.max_length": "Przekroczono maksymalną ilość znaków",
  "forms.error.message.min_length": "Minimalna ilość znaków: {{min_length}}",
  "forms.error.message.max": "Maksymalna wartość: {{max}}",
  "forms.error.message.invalid_url": "Niepoprawny adres url",
  "forms.error.message.general": "Niepoprawna wartość pola",
  "forms.error.message.at_least_one_capital": "Pole powinno zawierać wielką literę",
  "forms.error.message.at_least_one_lower": "Pole powinno zawierać małą literę",
  "forms.error.message.at_least_one_number": "Pole powinno zawierać cyfrę",
  "forms.error.message.at_least_one_symbol": "Pole powinno zawierać symbol - !@#$%^&*()",
  "forms.price-range.from": "Od",
  "forms.price-range.to": "Do",
  "forms.dropdown.placeholder": "Wybierz",
  "forms.dropdown.placeholder-all": "Wszystkie",
  "dashboard.welcome.welcome": "Witaj",
  "dashboard.welcome.message-1": "Witamy Cię na nowym portalu, dzięki któremu złożysz zamówienie dużo szybciej",
  "dashboard.welcome.message-2": "W tym miejscu masz dostęp do wszystkich produktów MILLE SAPORI IF&B!",
  "dashboard.welcome.message-3": "Najwyższej jakości certyfikowane włoskie produkty na wyciągnięcie Twojej ręki!",
  "dashboard.welcome.view-products": "Zobacz produkty",
  "dashboard.welcome.activity": "Aktywność",
  "dashboard.balance.your-balance": "Twoje saldo wynosi",
  "dashboard.balance.credit-limit": "Twój limit kupiecki to",
  "dashboard.balance.liabilities": "Suma zaległych zobowiązań",
  "dashboard.balance.full-info": "Pełne informacje",
  "dashboard.balance.price-gross": "Cena brutto",
  "dashboard.balance.sales-info.seller": "Twój doradca handlowy",
  "dashboard.balance.sales-info.write-now": "Napisz teraz",
  "dashboard.latest-documents.title": "Najnowsze zamówienia sprzedaży",
  "dashboard.latest-documents.all-documents": "Wszystkie dokumenty",
  "dashboard.buyer-details": "Dane nabywcy",
  "dashboard.payment-method": "Metoda płatności",
  "cl.account.login-success": "Zalogowano poprawnie",
  "cl.account.login-invalid-credentials": "Błędne hasło lub adres email",
  "cl.account.account_not_active": "Konto nie zostało aktywowane",
  "cl.account.eip_user_blocked_reason_all": "Konto zostało zablokowane. Skontaktuj się z administratorem",
  "cl.account.login-not-activated": "Konto nie zostało aktywowane",
  "cl.account.logout-success": "Wylogowano poprawnie",
  "cl.account.logout-error": "Błąd podczas wylogowywania",
  "cl.account.reset-password-request-success": "Email resetujący hasło został wysłany",
  "cl.account.reset-password-request-error": "Wystąpił błąd podczas przetwarzania zapytania",
  "cl.account.reset-password-success": "Hasło zostało zaktualizowane",
  "cl.account.reset-password-error": "Link do resetowania hasła jest nieprawidłowy",
  "cl.account.activate-success": "Konto zostało aktywowane",
  "cl.account.activate-error": "Wystąpił błąd podczas aktywacji konta",
  "cl.account.resend-activation-link-success": "Link aktywacyjny został wysłany",
  "cl.account.resend-activation-link-error": "Wystąpił błąd podczas przetwarzania zapytania",
  "profile.account-settings.title": "Ustawienia konta",
  "profile.account-settings.change-password": "Zmień hasło",
  "profile.account-settings.current-password": "Obecne hasło",
  "profile.account-settings.new-password": "Nowe hasło",
  "profile.account-settings.repeat-password": "Powtórz nowe hasło",
  "admin.nav.users-list": "Lista użytkowników",
  "admin.nav.notifications": "Powiadomienia",
  "admin.nav.banners": "Banery",
  "admin.nav.welcome-messages": "Wiadomości powitalne",
  "admin.nav.settings": "Ustawienia portalu",
  "admin.table.users.title": "Lista użytkowników",
  "admin.table.users.add-new-user-button": "Dodaj nowego użytkownika",
  "admin.table.users.salesperson": "Handlowiec:",
  "admin.table.users.deputy": "Zastępca:",
  "admin.table.users.header.customer-id": "ID klienta",
  "admin.table.users.header.email": "Adres e-mail",
  "admin.table.users.header.role": "Rola",
  "admin.table.users.header.activity": "Aktywność",
  "admin.table.users.header.salesperson": "Handlowiec",
  "admin.table.users.filters.select-role": "Wybierz role",
  "admin.table.users.filters.select-activity": "Wybierz aktywność",
  "admin.table.user-logs.title": "Aktywność użytkownika",
  "admin.table.user-logs.header.log-date": "Data logowania",
  "admin.table.user-logs.header.status": "Status",
  "admin.table.user-logs.status.user-create": "Utworzono użytkownika",
  "admin.table.user-logs.status.user-fail-logged": "Błąd logowania użytkownika",
  "admin.table.user-logs.status.user-locked": "Zablokowany użytkownika",
  "admin.table.user-logs.status.user-success-logged": "Logowanie zakończone sukcesem",
  "admin.table.user-logs.status.user-update": "Aktualizacja użytkownika",
  "admin.table.user-logs.status.user-delete": "Usunięto użytkownika",
  "admin.table.user-logs.status.generate-password": "Wygenerowano hasło",
  "admin.table.user-logs.status.user-success-password-change": "Pomyślna zmiana hasła użytkownika",
  "admin.table.user-logs.status.user-fail-password-change": "Niepowodzenie zmiany hasła użytkownika",
  "admin.table.user-logs.status.user-unlocked": "Użytkownik odblokowany",
  "admin.table.user-logs.status.user-notification-create": "Stworzono powiadomienie",
  "admin.table.user-logs.status.user-notification-delete": "Usunieto powiadomienie",
  "admin.table.user-logs.status.user-notification-update": "Aktualizacja powiadomienia",
  "admin.table.user-logs.status.user-welcome-message-create": "Stworzono wiadomość powitalną",
  "admin.table.user-logs.status.user-welcome-message-delete": "Usunieto wiadomość powitalną",
  "admin.table.user-logs.status.user-welcome-message-update": "Aktualizacja wiadomości powitalnej",
  "admin.table.user-logs.status.user-banner-create": "Stworzono baner",
  "admin.table.user-logs.status.user-banner-delete": "Usunieto baner",
  "admin.table.user-logs.status.user-banner-update": "Aktualizacja banera",
  "admin.table.user-logs.status.user-success-order": "Pomyślne zamówienie",
  "admin.table.user-logs.status.user-fail-order": "Niepowodzenie zamówienia",
  "admin.table.notifications.title": "Lista powiadomień",
  "admin.table.notifications.create-new-notifications-button": "Utwórz nowy alert",
  "admin.table.notifications.header.title": "Tytuł",
  "admin.table.notifications.header.description": "Tekst",
  "admin.table.notifications.header.active-date-from": "Aktywny od",
  "admin.table.notifications.header.active-date-to": "Aktywny do",
  "admin.table.notifications.header.edit": "Edycja",
  "admin.table.banners.title": "Lista banerów",
  "admin.table.banners.create-new-banners-button": "Utwórz nowy banner",
  "admin.table.banners.header.title": "Tytuł",
  "admin.table.banners.header.active-date-from": "Aktywny od",
  "admin.table.banners.header.active-date-to": "Aktywny do",
  "admin.table.banners.header.edit": "Edycja",
  "admin.table.welcome-messages.title": "Lista wiadomości powitalnych",
  "admin.table.welcome-messages.create-new-button": "Utwórz nową wiadomość",
  "admin.table.welcome-messages.header.name": "Nazwa",
  "admin.table.welcome-messages.header.description": "Wiadomość",
  "admin.table.welcome-messages.header.date-from": "Aktywna od",
  "admin.table.welcome-messages.header.date-to": "Aktywna do",
  "admin.table.welcome-messages.header.active": "Widoczność",
  "admin.table.welcome-messages.header.edit": "Edycja",
  "admin.users.title": "Zarządzanie {{email}}",
  "admin.users.nav.edit": "Edycja",
  "admin.users.nav.activity": "Aktywność",
  "admin.users.add.title": "Dodawanie użytkownika",
  "admin.users.add.set-role": "Przyznaj rolę",
  "admin.users.add.add-user": "Dodaj użytkownika",
  "admin.users.add.back": "Wstecz",
  "admin.users.add.user-has-been-added": "Użytkownik został dodany",
  "admin.users.add.errors.email.exists": "Taki email już występuje.",
  "admin.users.add.errors.eip.customer_user_email_not_found": "Nie można znaleźć użytkownika klienta z takim adresem e-mail w BC.",
  "admin.users.edit.title": "Edycja użytkownika",
  "admin.users.edit.set-role": "Przyznaj rolę",
  "admin.users.edit.save": "Zapisz",
  "admin.users.edit.back": "Wstecz",
  "admin.users.edit.user-has-been-added": "Użytkownik został zaktualizowany",
  "admin.users.edit.update-success": "Zaktualizowano użytkownika",
  "admin.users.edit.delete-success": "Usunięto użytkownika",
  "admin.users.edit.delete-title": "Usuwanie uzytkownika",
  "admin.users.edit.delete-description": "Czy jesteś pewny, ze chcesz usunac uzytkownika",
  "admin.users.edit.delete-yes": "Tak",
  "admin.users.edit.delete-no": "Nie",
  "admin.users.edit.blocked": "Zablokowany",
  "admin.users.edit.unblocked": "Odblokowany",
  "admin.users.edit.delete": "Usuń",
  "admin.users.edit.change-password": "Zmiana hasła",
  "admin.users.edit.change-password-title": "Czy na pewno chcesz zresetować hasło dla tego użytkownika?",
  "admin.users.edit.reset-password": "Resetuj hasło",
  "admin.users.edit.change-password-success": "Hasło użytkownika zostało pomyślnie wygenerowane",
  "admin.users.edit.errors.email.exists": "Taki email już występuje.",
  "admin.notifications.add.title": "Dodaj powiadomienie",
  "admin.notifications.add.name": "Nazwa",
  "admin.notifications.add.description": "Opis",
  "admin.notifications.add.start-date": "Data rozpoczęcia",
  "admin.notifications.add.end-date": "Data zakończenia",
  "admin.notifications.add.choose-date": "Wybierz datę",
  "admin.notifications.add.submit-button": "Dodaj powiadomienie",
  "admin.notifications.add.visible": "Widoczny",
  "admin.notifications.add.invisible": "Niewidoczny",
  "admin.notifications.add.alert-visibility": "Widoczność powiadomienia",
  "admin.notifications.add.notification-has-been-added": "Powiadomienie został dodany",
  "admin.notifications.edit.title": "Edycja {{notification}}",
  "admin.notifications.edit.update-success": "Zaktualizowano powiadomienie",
  "admin.notifications.edit.delete-success": "Usunięto powiadomienie",
  "admin.notifications.edit.save": "Zapisz",
  "admin.notifications.edit.delete-title": "Usuwanie powiadomienia",
  "admin.notifications.edit.delete-description": "Na pewno chcesz usunac powiadomienie?",
  "admin.banners.add.title": "Dodaj baner",
  "admin.banners.add.name": "Tytuł",
  "admin.banners.add.url": "Adres URL",
  "admin.banners.add.start-date": "Data rozpoczęcia",
  "admin.banners.add.end-date": "Data zakończenia",
  "admin.banners.add.image": "Zdjęcie",
  "admin.banners.add.banner-visibility": "Widoczność baneru",
  "admin.banners.add.visible": "Widoczny",
  "admin.banners.add.invisible": "Niewidoczny",
  "admin.banners.add.submit-button": "Dodaj baner",
  "admin.banners.add.banner-has-been-added": "Banner został dodany",
  "admin.banners.edit.title": "Edycja {{banner}}",
  "admin.banners.edit.submit-button": "Zapisz",
  "admin.banners.edit.update-success": "Zaktualizowano baner",
  "admin.banners.edit.delete-success": "Usunięto baner",
  "admin.banners.edit.delete-title": "Usuwanie baneru",
  "admin.banners.edit.delete-description": "Na pewno chcesz usunąć baner?",
  "admin.welcome-messages.add.title": "Dodaj wiadomość powitalną",
  "admin.welcome-messages.add.name": "Nazwa",
  "admin.welcome-messages.add.url": "Adres URL",
  "admin.welcome-messages.add.description": "Wiadomość powitalna",
  "admin.welcome-messages.add.start-date": "Data rozpoczęcia",
  "admin.welcome-messages.add.end-date": "Data zakończenia",
  "admin.welcome-messages.add.banner-visibility": "Widoczność",
  "admin.welcome-messages.add.visible": "Widoczna",
  "admin.welcome-messages.add.invisible": "Niewidoczna",
  "admin.welcome-messages.add.submit-button": "Dodaj wiadomość powitalną",
  "admin.welcome-messages.add.add-success": "Wiadomość powitalna została dodana",
  "admin.welcome-messages.add.add-action": "Dodaj przycisk",
  "admin.welcome-messages.add.remove-action": "Usuń przycisk",
  "admin.welcome-messages.add.action-name": "Nazwa",
  "admin.welcome-messages.add.action-buttons": "Przyciski akcji",
  "admin.welcome-messages.edit.title": "Edycja",
  "admin.welcome-messages.edit.submit-button": "Zapisz",
  "admin.welcome-messages.edit.update-success": "Zaktualizowano wiadomość powitalną",
  "admin.welcome-messages.edit.delete-success": "Usunięto wiadomość powitalną",
  "admin.welcome-messages.edit.delete-title": "Usuwanie wiadomości powitalnej",
  "admin.welcome-messages.edit.delete-description": "Na pewno chcesz usunąć wiadomość powitalną?",
  "admin.settings.title": "Ustawienia portalu",
  "admin.settings.select-default-category": "Wybierz domyślną kategorię",
  "admin.settings.save": "Zapisz",
  "admin.settings.success": "Zmiany zostały zapisane",
  "admin.settings.error": "Nie udało się zapisać zmian",
  "ROLE_ADMIN": "Administrator",
  "ROLE_SALESPERSON": "Sprzedawca",
  "ROLE_SALESPERSON_POS": "Sprzedawca POS",
  "ROLE_USER": "Użytkownik",
  "user.not.found": "Nie znaleziono użytkownika",
  "cart.random-products.title": "Proponowane produkty",
  "cart.back": "Kontynuuj zakupy",
  "cart.title": "Zawartość koszyka",
  "cart.list.label.price": "Cena netto",
  "cart.list.label.qty": "Ilość",
  "cart.list.label.price-total": "Wartość netto",
  "cart.list.label.vat": "Vat",
  "cart.list.label.price-gross-total": "Całkowita cena brutto",
  "cart.summary.title": "Podsumowanie",
  "cart.summary.price-netto": "Kwota netto:",
  "cart.summary.price-vat": "VAT:",
  "cart.summary.price-total": "Łączna kwota:",
  "cart.summary.external-document": "Nr zewnętrzny zamówienia",
  "cart.empty.title": "Twój koszyk jest pusty",
  "cart.form.title": "Finalizuj zamówienie",
  "cart.form.date.placeholder": "Wybierz datę dostawy",
  "cart.form.method.placeholder": "Wybierz metodę dostawy",
  "cart.form.notes.placeholder": "Uwagi do zamówienia",
  "cart.form.submit.btn": "Złóż zamówienie",
  "cart.products.added.1": "Dodano 1 produkt do koszyka",
  "cart.products.added.2-4": "Dodano {{qty}} produkty do koszyka",
  "cart.products.added.5+": "Dodano {{qty}} produktów do koszyka",
  "cart.products.removed.1": "Usunięto 1 produkt z koszyka",
  "cart.products.removed.2-4": "Usunięto {{qty}} produkty z koszyka",
  "cart.products.removed.5+": "Usunięto {{qty}} produktów z koszyka",
  "cart.order.success": "Twoje zamówienie zostało złożone",
  "cart.order.error": "Wystąpił błąd podczas składania zamówienia",
  "cart.success.title": "Koszyk",
  "cart.success.description": "Twoje zamówienie zostało złożone pomyślnie.",
  "cart.button.back": "Kontynuuj zakupy",
  "cart.suggested.btn": "Dostępny produkt alternatywny",
  "cart.suggested.popup.title": "Czy chcesz dodać produkt mimo niewystarczającej ilości czy chcesz sprawdzić dostępne substytuty tego produktu",
  "payment.method.transport": "Kurier",
  "payment.method.pickup": "Odbiór osobisty",
  "uploader.browse": "przeglądaj",
  "dropdown.placeholder.select": "Wybierz",
  "dropdown.placeholder.select-address": "Wybierz adres",
  "dropdown.placeholder.select-category": "Wybierz kategorię",
  "dropdown.placeholder.select-variety": "Wybierz rodzaj",
  "calendar.weekday.0": "Pon.",
  "calendar.weekday.1": "Wt.",
  "calendar.weekday.2": "Śr.",
  "calendar.weekday.3": "Czw.",
  "calendar.weekday.4": "Pt.",
  "calendar.weekday.5": "Sob.",
  "calendar.weekday.6": "Ndz.",
  "calendar.month.0": "Styczeń",
  "calendar.month.1": "Luty",
  "calendar.month.2": "Marzec",
  "calendar.month.3": "Kwiecień",
  "calendar.month.4": "Maj",
  "calendar.month.5": "Czerwiec",
  "calendar.month.6": "Lipiec",
  "calendar.month.7": "Sierpień",
  "calendar.month.8": "Wrzesień",
  "calendar.month.9": "Październik",
  "calendar.month.10": "Listopad",
  "calendar.month.11": "Grudzień",
  "calendar.placeholder.input": "Wybierz datę",
  "documents.menu.sales": "Zamówienia sprzedaży",
  "documents.menu.financial": "Dokumenty finansowe",
  "documents.menu.liabilities": "Zaległe zobowiązania",
  "documents.table.title.sales": "Wszystkie zamówienia sprzedaży",
  "documents.table.title.financial": "Dokumenty finansowe",
  "documents.table.title.liabilities": "Zaległe zobowiązania",
  "documents.table.header.type": "Rodzaj dokumentu",
  "documents.table.header.date": "Data zamówienia",
  "documents.table.header.number": "Numer dokumentu",
  "documents.table.header.last_shipping_no": "Nr zaksięgowanego wydania",
  "documents.table.header.invoice_no": "Nr zaksięgowanej faktury",
  "documents.table.header.price": "Kwota",
  "documents.table.shipping_phone_no": "Nr telefonu kierowcy:",
  "documents.open-pdf.error": "Nie udało się pobrać szczegółów",
  "documents.payments.pay": "Zapłać",
  "documents.payments.sum": "Suma do zapłaty:",
  "documents.payments.processing": "Płatność w trakcie realizacji...",
  "documents.payments.cancel": "Anuluj płatność",
  "financial-documents.table.header.select": "Wybierz",
  "financial-documents.table.header.type": "Typ dokumentu",
  "financial-documents.table.header.number": "Numer dokumentu",
  "financial-documents.table.header.date": "Data wystawienia",
  "financial-documents.table.header.original-amount": "Kwota faktury",
  "financial-documents.table.header.remaining-amount": "Pozostało do zapłaty",
  "financial-documents.table.header.due-date": "Termin płatności",
  "financial-documents.table.return-product": "Zwróć",
  "datepicker.clear": "Wyczyść",
  "ROLE_USER_LIMITED": "Ograniczony użytkownik",
  "footer.contact.title": "Kontakt",
  "footer.contact.share-capital": "Wysokość kapitału zakładowego:",
  "footer.shop": "Włoska Akademia Szefów Kuchni Mille Sapori IF&B",
  "footer.description": "Włoska Akademia Szefów Kuchni jest częścią Mille Sapori Plus IF&B. Akademia organizuje szkolenia dla profesjonalistów, jak również dla pasjonatów włoskich smaków. Zdobytą wiedzą pragniemy podzielić się z szefami kuchni, przyszłymi kucharzami, właścicielami restauracji i pasjonatami włoskich smaków. Ponadto aranżujemy pokazy kulinarne, degustacje czy spotkania z producentami.\n\nZapraszamy do kontaktu.",
  "footer.links.title": "Linki",
  "footer.links.info-clause": "Klauzula informacyjna",
  "footer.links.privacy-policy": "Polityka prywatności",
  "polish": "Polski",
  "english": "Angielski",
  "deutsch": "Niemiecki",
  "name-and-lastname": "Imie i Nazwisko",
  "id-number": "Numer identyfikujący",
  "select-salesperson-role": "Wybierz role sprzedawcy",
  "select-cities": "Wybierz miasta",
  "select-lang": "Wybierz język",
  "select-company": "Wybierz firmę",
  "activity": "Aktywność",
  "btn.switch-user": "Zmień klienta",
  "btn.profile": "Profil",
  "btn.logout": "Wyloguj",
  "btn.back": "Wstecz",
  "concessions.auth-ends-title": "Szanowny Kliencie, Uprzejmie informujemy, że za kilka dni upływa termin ważności Twojej koncesji za: ",
  "concessions.payment-ends-title": "Szanowny Kliencie, Uprzejmie informujemy, że za kilka dni upływa termin płatności za Twoje korzystanie z zezwolenia na: ",
  "concessions.beer": "sprzedaż napojów alkoholowych do 4,5% alkoholu oraz piwo",
  "concessions.spirit": "sprzedaż napojów alkoholowych powyżej 18%",
  "concessions.wine-spririts-to-18": "sprzedaż napojów alkoholowych powyżej 4,5% do 18% z wyjątkiem piwa",
  "concessions.description": "Prześlij potwierdzenie opłaty za zezwolenie  do swojego Doradcy Handlowego",
  "blockades.Invoice.title": "Szanowny Kliencie,",
  "blockades.Invoice.description": "Z powodu zaległości Twoje konto zostało zawieszone. Przejdź do okna <b>Suma zaległych zobowiązań</b> na Panelu głównym gdzie znajdziesz szczegóły dotyczące przeterminowanych faktur. Po uregulowaniu zaległości Twoje konto zostanie odwieszone.",
  "blockades.Invoice.description-limited": "Z powodu zaległości Twoje konto zostało zawieszone na składanie zamówień.  W przypadku wątpliwości skontaktuj się ze swoim Doradcą Handlowym.",
  "blockades.Ship.title": "Szanowny Kliencie,",
  "blockades.Ship.description": "Z powodu zaległości Twoje konto zostało zawieszone. Przejdź do okna <b>Suma zaległych zobowiązań</b> na Panelu głównym gdzie znajdziesz szczegóły dotyczące przeterminowanych faktur. Po uregulowaniu zaległości Twoje konto zostanie odwieszone.",
  "blockades.Ship.description-limited": "Z powodu zaległości Twoje konto zostało zawieszone na składanie zamówień.  W przypadku wątpliwości skontaktuj się ze swoim Doradcą Handlowym.",
  "blockades.limit.title": "Szanowny Kliencie,",
  "blockades.limit.description": "Z powodu przekroczenia <b>Limitu kupieckiego</b>, złożenie kolejnego zamówienia nie jest obecnie możliwe. Prosimy o uregulowanie najstarszych faktur celem zmniejszania <b>Aktualnego Salda</b>. Szczegóły znajdziesz na Panelu głównym w oknie <b>Twoje saldo wynosi</b> lub w <b>Sumie zaległych zobowiązań</b>.",
  "categories.title": "Lista kategorii",
  "categories.search": "Wyszukaj kategorię",
  "categories.no-categories": "Brak kategorii",
  "language.en-EN": "EN",
  "language.de-DE": "DE",
  "language.pl-PL": "PL",
  "language.es-ES": "ES",
  "currency.PLN": "{{ value }} PLN",
  "currency.EUR": "{{ value }} EUR",
  "document-preview.title.sales": "Zamówienie sprzedaży:",
  "document-preview.title.latest-sales": "Zamówienie sprzedaży:",
  "document-preview.title.financial": "Numer dokumentu:",
  "document-preview.title.liabilities": "Numer dokumentu:",
  "document-preview.download": "Pobierz dokument",
  "document-preview.back.sales": "Powrót do listy zamówień sprzedaży",
  "document-preview.back.latest-sales": "Powrót do listy zamówień sprzedaży",
  "document-preview.back.financial": "Powrót do listy dokumentów finansowych",
  "document-preview.back.liabilities": "Powrót do listy zaległych zobowiązań",
  "document-edit.title": "Edycja zamówienia:",
  "document-edit.submit": "Zatwierdź zmiany",
  "document-edit.success": "Zmiany zostały zapisane",
  "document-edit.error": "Nie udało się zapisać zmian",
  "document-edit.warning": "Cena może ulec zmianie po zapisaniu zmian, ze względu na ponowne przeliczenie rabatów",
  "document-edit.empty-order": "Zamówienie jest puste",
  "return-product.title": "Zwracam produkty",
  "return-product.submit": "Zwracam produkty",
  "return-product.choose-reason": "Wybierz powód zwrotu",
  "return-product.select-product": "Wybierz produkty, które chcesz zwrócić"
}
