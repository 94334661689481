import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { selectBlocked, selectFeatures, selectLoaded, selectSkipCreditLimitVerification, selectUser } from '../selectors/user.selector';
import { userActions } from '../actions/user.actions';
import { ChangePasswordValue, User } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    user$ = this.store.select(selectUser).pipe(filter((data): data is User => data !== null));
    features$ = this.store.select(selectFeatures);
    blocked$ = this.store.select(selectBlocked);
    skipCreditLimitVerification$ = this.store.select(selectSkipCreditLimitVerification).pipe(filter((skip): skip is boolean => skip !== null));

    get user(): User | null {
        let value: User | null = null;
        this.store
            .select(selectUser)
            .pipe(
                take(1),
                tap((user) => (value = user)),
            )
            .subscribe();
        return value;
    }

    getUser(): void {
        this.store.dispatch(userActions.getUser());
    }

    changePassword(value: ChangePasswordValue): void {
        this.store.dispatch(userActions.changePassword({ value }));
    }

    clear(): void {
        this.store.dispatch(userActions.clear());
    }
}
