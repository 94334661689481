import { UserBlocked, UserBlockedDto } from '@app/store/models';
import { Feature } from '@app/shared/types';
import { options } from '@options';
import { UserDto, UserImpersonateDto, UserSalespersonDto, UserSalespersonInfoDto } from './user.dto';

export class User {
    id: string;
    name: string;
    roles: string[];
    features: Feature[];
    salesperson: UserSalesperson | null;
    salespersonInfo?: UserSalespersonInfo | null;
    impersonate?: UserImpersonate | null;

    constructor(userDto: UserDto) {
        this.id = userDto.id;
        this.name = userDto.name;
        this.roles = userDto.roles;
        this.salesperson = userDto.salesperson ? new UserSalesperson(userDto.salesperson) : null;
        this.salespersonInfo = userDto.salesperson_info ? new UserSalespersonInfo(userDto.salesperson_info) : null;
        this.impersonate = userDto.impersonate ? new UserImpersonate(userDto.impersonate) : null;

        const role = this.roles[0];
        const salespersonRole = userDto.salesperson?.role;
        const roleFeatures = role ? options.roleFeatures[role] : undefined;
        const salespersonRoleFeatures = salespersonRole ? options.salespersonRoleFeatures[salespersonRole] : undefined;

        if (role === 'ROLE_SALESPERSON') {
            this.features = salespersonRoleFeatures ?? roleFeatures ?? [];
        } else {
            this.features = roleFeatures ?? [];
        }
    }
}

export class UserImpersonate {
    no: string;
    name: string;
    email: string;
    locationCode: string;
    salespersonCode: string;

    constructor(data: UserImpersonateDto) {
        this.no = data.no;
        this.email = data.email;
        this.name = `${data.name}${data.name2 ?? ''}`.trim();
        this.locationCode = data.location_code;
        this.salespersonCode = data.salesperson_code;
    }
}

export class UserSalesperson {
    code: string;
    email: string;
    name: string;
    role: string;
    locationCode: string;
    locationFilters: string[];
    image: string;

    constructor(data: UserSalespersonDto) {
        this.code = data.code;
        this.email = data.email;
        this.name = data.name;
        this.role = data.role;
        this.locationCode = data.location_code;
        this.locationFilters = data.location_filter;
        this.image = data.image;
    }
}

class UserSalespersonInfo {
    no: string;
    name: string;
    balance: number;
    balanceDue: number;
    creditLimitLcy: number;
    salesPersonCode: string;
    locationCode: string;
    blocked: UserBlocked | null;
    restaurantName: string;
    vatRegistrationNo: string;
    postcode: string;
    city: string;
    addresses: string;
    paymentTerms: string;
    paymentMethod: string;
    skipCreditLimitVerification: boolean;

    constructor(data: UserSalespersonInfoDto) {
        this.no = data.no;
        this.name = `${data.name}${data.name2 ?? ''}`.trim();
        this.balance = Math.round(100 * Number(data.balance ?? 0));
        this.balanceDue = Math.round(100 * Number(data.balance_due ?? 0));
        this.creditLimitLcy = Math.round(100 * Number(data.credit_limit_lcy ?? 0));
        this.salesPersonCode = data.sales_person_code;
        this.locationCode = data.location_code;
        this.blocked = this.getBlockedValue(data.blocked);
        this.restaurantName = data.restaurant_name;
        this.vatRegistrationNo = data.vat_registration_no;
        this.postcode = data.post_code;
        this.city = data.city;
        this.addresses = data.addresses;
        this.paymentTerms = data.payment_terms;
        this.paymentMethod = data.payment_method;
        this.skipCreditLimitVerification = data.skip_credit_limit_verification;
    }

    getBlockedValue(blocked: UserBlockedDto) {
        switch (blocked) {
            case 'All':
                return 'All';
            case 'Ship':
                return 'Ship';
            case 'Invoice':
                return 'Invoice';
            default:
                return null;
        }
    }
}
