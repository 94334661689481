import { Injectable, inject } from '@angular/core';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { I18nHtmlLangService } from './html-lang.service';
import { LocalStorage } from '@app/shared/classes';
import { Lang } from '../lang.type';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private translateService = inject(TranslateService);
    private i18nHtmlLangService = inject(I18nHtmlLangService);
    language$ = new BehaviorSubject<Lang>(environment.defaultLanguage);
    changed$ = new Subject<string>();

    constructor() {
        this.init();
    }

    init() {
        this.translateService.setDefaultLang(environment.defaultLanguage);
        const lang = LocalStorage.read<Lang>('language');

        if (lang && environment.supportedLanguages.includes(lang)) {
            this.translateService.use(lang);
            this.language$.next(lang);
            this.changed$.next(this.get());
        } else {
            this.language$.next(this.get());
            this.changed$.next(this.get());
        }
    }

    set(lang: Lang): void {
        this.translateService.use(lang);
        this.i18nHtmlLangService.setLang(lang);
        LocalStorage.write('language', lang);
        this.language$.next(lang);
        this.changed$.next(this.get());
    }

    get(): Lang {
        if (!this.translateService.currentLang) {
            this.translateService.use(environment.defaultLanguage);
        }

        return this.translateService.currentLang as Lang;
    }
}
